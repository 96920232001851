import { defineStore } from 'pinia';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';

export const useUsers = defineStore('users', {
  state: () => ({
    profile: {
      id: null,
      username: null,
      email: null,
      avatar: null,
      isStaff: false,
      isSuperUser: false,
      permissions: {
        usecase_1: new Set(),
        usecase_2: new Set(),
        usecase_4: new Set(),
      },
    },
    appHash: '',
  }),
  actions: {
    setProfile(profileData) {
      this.profile.id = profileData.id;
      this.profile.username = profileData.username;
      this.profile.email = profileData.email;
      this.profile.avatar = profileData.avatar;
      this.profile.isStaff = profileData.is_staff;
      this.profile.isSuperUser = profileData.is_superuser;
      for (const permission of profileData.permissions) {
        const [app, perm] = permission.split('.');
        if (typeof this.profile.permissions[app] !== 'undefined') {
          this.profile.permissions[app].add(perm);
        }
      }
    },
    async patchProfile(accountForm) {
      const profileData = await axios({
        method: 'POST',
        url: '/api/profile/',
        data: accountForm,
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      this.setProfile(profileData.data.user);
    },
    async getProfile() {
      try {
        const profileData = await axios({
          method: 'GET',
          url: '/api/profile/',
        });
        this.setProfile(profileData.data.user);
      } catch (error) {
        console.error(error);
        this.$reset();
        return Promise.reject(error);
      }
    },
    async patchPassword(passwordOld, passwordNew) {
      await axios({
        url: '/api/profile/password/',
        data: { passwordOld, passwordNew },
        method: 'PATCH',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
    },
    async getClientHash() {
      await axios({ url: '/build-info.json', method: 'GET' })
        .then((resp) => {
          if (resp.data['build-id'] !== import.meta.env.VITE_BUILD_ID) {
            console.log('client update: forcing browser refresh');
            window.location.reload();
          }
        })
        .catch(() => {
          this.appHash = '';
        });
    },
  },
  getters: {
    hasPermission: (state) => {
      return (app, perm) =>
        state.profile.isSuperUser || state.profile.permissions[app].has(perm);
    },
    uc1CanReadGlobal(state) {
      return this.hasPermission('usecase_1', 'view_usecase_1');
    },
    uc1CanExportData(state) {
      return this.hasPermission('usecase_1', 'export_data_usecase_1');
    },
    uc1CanAddProject(state) {
      return this.hasPermission('usecase_1', 'add_project');
    },
    uc1CanChangeProject(state) {
      return this.hasPermission('usecase_1', 'change_project');
    },
    uc1CanDeleteProject(state) {
      return this.hasPermission('usecase_1', 'delete_project');
    },
    uc1CanAddScenario(state) {
      return this.hasPermission('usecase_1', 'add_scenario');
    },
    uc1CanChangeScenario(state) {
      return this.hasPermission('usecase_1', 'change_scenario');
    },
    uc1CanDeleteScenario(state) {
      return this.hasPermission('usecase_1', 'delete_scenario');
    },
    uc1CanAddAssumptionsScaling(state) {
      return this.hasPermission(
        'usecase_1',
        'add_assumptionsscalingfactordemand',
      );
    },
    uc1CanChangeAssumptionsScaling(state) {
      return this.hasPermission(
        'usecase_1',
        'change_assumptionsscalingfactordemand',
      );
    },
    uc1CanDeleteAssumptionsScaling(state) {
      return this.hasPermission(
        'usecase_1',
        'delete_assumptionsscalingfactordemand',
      );
    },
    uc1CanAddAssumptionsIncrease(state) {
      return this.hasPermission(
        'usecase_1',
        'add_assumptionstotalincreasegeneration',
      );
    },
    uc1CanChangeAssumptionsIncrease(state) {
      return this.hasPermission(
        'usecase_1',
        'change_assumptionstotalincreasegeneration',
      );
    },
    uc1CanDeleteAssumptionsIncrease(state) {
      return this.hasPermission(
        'usecase_1',
        'delete_assumptionstotalincreasegeneration',
      );
    },
    uc1CanAddAssumptionsGeneral(state) {
      return this.hasPermission('usecase_1', 'add_assumptionsgeneral');
    },
    uc1CanChangeAssumptionsGeneral(state) {
      return this.hasPermission('usecase_1', 'change_assumptionsgeneral');
    },
    uc1CanDeleteAssumptionsGeneral(state) {
      return this.hasPermission('usecase_1', 'delete_assumptionsgeneral');
    },
    uc1CanAddAssumptionsScenario(state) {
      return this.hasPermission('usecase_1', 'add_assumptionsscenario');
    },
    uc1CanChangeAssumptionsScenario(state) {
      return this.hasPermission('usecase_1', 'change_assumptionsscenario');
    },
    uc1CanDeleteAssumptionsScenario(state) {
      return this.hasPermission('usecase_1', 'delete_assumptionsscenario');
    },
  },
});
