<template>
  <div>
    <ButtonEl
      color="color2"
      icon="unarchive"
      text="Parametrierungen verwalten"
      @click="openDialog"
    />
    <AssumptionModalGeneralInfos
      title="Parametrierungen zur Nachfrage"
      :selected
      :selectables
      :date="selectedScalingFactor.created_at"
      :description="selectedScalingFactor.description"
      :load-dialog
      :is-loading
      :can-delete
      base-url="/api/usecase-1/assumptions-scaling-factors-demand/"
      @apply-values="applyValues($event)"
      @delete-values="fetchInstances"
      @update:load-dialog="loadDialog = $event"
      @update:selected="selected = $event"
    >
      <AssumptionsScalingFactorsRow
        v-for="scalingFactor in scalingFactorsForDisplay"
        :key="scalingFactor.title"
        :item="scalingFactor.item"
        :title="scalingFactor.title"
      />
    </AssumptionModalGeneralInfos>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import axios from '@/utils/axiosHelper';
import AssumptionsScalingFactorsRow from './AssumptionsScalingFactorsRow.vue';
import ButtonEl from '../../../../components/button/ButtonEl.vue';
import AssumptionModalGeneralInfos from '../shared/AssumptionModalGeneralInfos.vue';
import { getScalingFactorAssumptionsForDisplay } from '../helpers/modal';
import { useProjectResult } from '../../projects/project-result-detail/project-result-store';

const resultStore = useProjectResult();
const emit = defineEmits(['apply-values']);

const props = defineProps({
  scenarioId: {
    type: String,
    required: true,
  },
  canDelete: {
    type: Boolean,
    default: false,
  },
});

const loadDialog = ref(false);
const instances = ref([]);
const selectables = ref([]);
const selected = ref();
const applied = computed(() => resultStore.appliedDemandScalingId);
const isLoading = ref(false);

function fetchInstances() {
  isLoading.value = true;
  axios({
    method: 'GET',
    url: '/api/usecase-1/assumptions-scaling-factors-demand/',
    params: { scenario_id: props.scenarioId },
  }).then((resp) => {
    instances.value = resp.data.results;
    selectables.value = [];
    for (const item of instances.value) {
      selectables.value.push({ id: item.id, title: item.title });
    }
    if (applied.value) selected.value = applied.value;
    else if (instances.value.length) selected.value = instances.value[0].id;
    isLoading.value = false;
  });
}

const selectedScalingFactor = computed(() => {
  return instances.value.find((e) => e.id === selected.value) || {};
});

const scalingFactorsForDisplay = computed(() =>
  getScalingFactorAssumptionsForDisplay(selectedScalingFactor.value),
);

function openDialog() {
  fetchInstances();
  loadDialog.value = true;
}

function applyValues(instanceId) {
  emit('apply-values', {
    scalingElectrificationMobility:
      selectedScalingFactor.value.electrification_mobility,
    scalingElectrificationHeatSingle:
      selectedScalingFactor.value.electrification_heat_single,
    scalingElectrificationHeatNetwork:
      selectedScalingFactor.value.electrification_heat_network,
    id: instanceId,
  });
  loadDialog.value = false;
}
</script>

<style scoped></style>
