<template>
  <div class="w-full h-screen relative flex">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    >
      <template #header>
        <LayerPanelAggregationLevels :global="true" />
      </template>
      <template #layer-toggles="slotProps">
        <AggregationToggle v-bind="slotProps" />
      </template>
    </LayerPanel>
    <div class="mapView h-full relative w-full">
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="selectedScenarioId"
          :base-year="projectBaseYear"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
        />
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <ScenarioSelector
            :scenarios="project.scenarioDtos.filter((e) => e.progress === 0)"
            :selected-scenario-id
            :project="project.projectDto"
            :map-store
            @select-scenario="selectedScenarioId = $event"
          />
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <TimeMachine
            :map-store
            data-test="time-machine"
            :years="projectYears"
          />
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-1/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import TimeMachine from '@/apps/features/map/control-options/components/TimeMachine.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { Project } from '@/apps/usecase-1/map/project';
import AggregationToggle from '@/apps/usecase-1/map/layer-panel/AggregationToggle.vue';
import { useMapStore } from './map-store';
import LayerPanelAggregationLevels from '@/apps/features/map/layer-panel/input-forms/LayerPanelAggregationLevels.vue';
import ScenarioSelector from '@/apps/usecase-1/map/control-options/components/ScenarioSelector.vue';
import { panelObjects } from '@/apps/usecase-1/map/info-panel/Info-panel-objects';
import axios from '@/utils/axiosHelper';
import { fitBBox } from '@/mapbox/main';

const mapStore = useMapStore();

const route = useRoute();
const router = useRouter();

const showLegend = ref(false);
const loaded = ref(false);

onMounted(async () => {
  if (setupError) {
    await router.push({ name: 'mapProjectSelection', params: {} });
    return;
  }
  initializeMap();
});

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
}

function flyToBBox() {
  return axios({
    method: 'GET',
    url: `/api/usecase-1/initial-map-bbox/${project.projectDto.id}/`,
  }).then((resp) => {
    const bbox = resp.data.bbox;
    fitBBox(bbox);
  });
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

let project;
const selectedScenarioId = ref();
const currentScenarioDto = ref();
const projectBaseYear = ref();
const projectYears = ref();

watch(selectedScenarioId, async (newScenarioId) => {
  await router.push({
    name: 'mapView1',
    params: { projectId: project.projectDto.id, scenarioId: newScenarioId },
  });
  currentScenarioDto.value = project.scenarioDtos.find(
    (s) => s.id === newScenarioId,
  );
  mapStore.selectedAnalysis = currentScenarioDto.value;
});

async function getProjectAndScenarios() {
  project = await Project.fetchDeep(route.params.projectId);
  projectBaseYear.value = project.projectDto.base_year;
  projectYears.value = project.projectDto.years;
  if (project.scenarioDtos.length === 0) {
    throw Error('project contains no scenarios');
  }
  currentScenarioDto.value = project.scenarioDtos.find(
    (s) => s.id.toString() === route.params.scenarioId,
  );

  if (currentScenarioDto.value == null) {
    throw Error(
      'Scenario with ID "' + route.params.scenarioId + '" does not exist',
    );
  }
  selectedScenarioId.value = currentScenarioDto.value.id;
  mapStore.selectedAnalysis = currentScenarioDto.value;
  mapStore.setYear(projectBaseYear.value);
}

let setupError = false;
try {
  await getProjectAndScenarios();
} catch (err) {
  console.log('Error getting project and scenario: ', err.message);
  //router.push does not work correctly in <script setup> block,
  //therefore set setupError so that onMounted hook does it
  setupError = true;
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
