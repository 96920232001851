<template>
  <TopHeaderBar class="justify-start">
    <div class="flex items-center gap-4">
      <IconWrapper :size="48" :icon="IconDevelopment" fill="text-core-color2" />
      <h1 class="text-title-neutral">Szenariorahmen</h1>
    </div>
  </TopHeaderBar>
  <ParameterSetView
    title="Szenariorahmen"
    url="/api/usecase-1/assumptions-scenario/"
    :release-option="true"
    :exclude-fields="['created_by']"
    :exclude-from-request-if-null="['is_released']"
    :enable-release="true"
    :form-max-height-is-height="true"
    form-title="Szenariorahmen"
    create-button-text="Neuen Szenariorahmen anlegen"
    :create-dialog="true"
    :can-create-instance="userStore.uc1CanAddAssumptionsScenario"
    :is-read-only="
      (instance) =>
        instance.is_released || !userStore.uc1CanChangeAssumptionsScenario
    "
    :is-released="(instance) => instance.is_released"
    :enable-delete="
      (instance) =>
        !instance.has_scenario && userStore.uc1CanDeleteAssumptionsScenario
    "
    :content-structure="contentStructureAssumptionsScenario"
    :table-headers="tableHeaders"
  />
</template>

<script setup>
import ParameterSetView from '@/apps/features/parameter-set/ParameterSetView.vue';
import { contentStructureAssumptionsScenario } from '@/apps/usecase-1/assumptions-scenario/contentStructureAssumptionsScenario';
import IconDevelopment from '@/assets/icons/custom/misc/IconDevelopment.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import { useUsers } from '@/apps/auth/users-store';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const userStore = useUsers();

const tableHeaders = [
  {
    title: 'Name',
    key: 'title',
    width: '150px',
  },
  {
    title: 'Erstellt am',
    key: 'date',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Status',
    key: 'isFinished',
    align: 'center',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Aktionen',
    key: 'functions',
    align: 'end',
    sortable: false,
    width: '125px',
  },
];
</script>
