import { defineStore } from 'pinia';
import axios from '@/utils/axiosHelper';

export const useRestrictionsStore = defineStore('restrictions', {
  state: () => ({
    categoriesNamingMap: {},
    subCategoriesNamingMap: {},
    categoriesData: [],
  }),
  actions: {
    async fetchRestrictions() {
      if (this.categoriesData.length === 0) {
        const data = await axios({
          method: 'GET',
          url: '/api/usecase-2/restrictions/',
        });
        this.categoriesNamingMap = data.data.categories;
        this.subCategoriesNamingMap = data.data.subcategories;
        this.categoriesData = data.data.grouped_categories;
      }
    },
  },
});
