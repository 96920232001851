<template>
  <div class="flex flex-col gap-5">
    <InputEl
      v-model="specificPlantSize"
      label="Spezifische Anlagengröße"
      input-type="float"
      placeholder="Bitte eingeben"
      class="w-full"
      :disabled="readOnly"
      suffix="kW/ha"
      :rules="getValidationRules(formRules.specific_plant_size_kw_m2)"
    />
    <InputEl
      v-model="dcAcRatio"
      label="DC/AC Ratio"
      input-type="percent"
      placeholder="Bitte eingeben"
      suffix="%"
      class="w-full"
      :disabled="readOnly"
      :rules="getValidationRules(formRules.dc_ac_ratio, null, 'percent')"
    />
    <InputEl
      v-model="inverterEfficiency"
      label="Wechselrichter-Wirkungsgrad"
      input-type="percent"
      placeholder="0"
      suffix="%"
      class="w-full"
      :disabled="readOnly"
      :rules="
        getValidationRules(formRules.inverter_efficiency, null, 'percent')
      "
    />
    <InputEl
      v-model="performanceRatio"
      label="Performance Ratio"
      input-type="percent"
      placeholder="Bitte eingeben"
      suffix="%"
      class="w-full"
      :disabled="readOnly"
      :rules="getValidationRules(formRules.performance_ratio, null, 'percent')"
    />
    <InputEl
      v-model="mountingSlopeDegree"
      label="Neigungswinkel durch Aufständerung"
      input-type="float"
      placeholder="Bitte eingeben"
      class="w-full"
      suffix="°"
      :disabled="readOnly"
      :rules="getValidationRules(formRules.mounting_slope_degree)"
    />
  </div>
</template>

<script setup>
import InputEl from '@/components/input/InputEl.vue';
import { getValidationRules } from '@/utils/rest-utils';
import { toRefs, useVModel } from '@vueuse/core';

const props = defineProps({
  formRules: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const {
  specific_plant_size_kw_m2: specificPlantSize,
  dc_ac_ratio: dcAcRatio,
  inverter_efficiency: inverterEfficiency,
  performance_ratio: performanceRatio,
  mounting_slope_degree: mountingSlopeDegree,
} = toRefs(useVModel(props, 'modelValue', emit));
</script>
