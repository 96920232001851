<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="mapStore.showScenarioSelector"
      :class="{ 'rounded-b-none': isExpanded }"
      class="bg-white standard-elevation-2 rounded-[8px] outer-container z-20"
    >
      <div class="relative w-full flex p-1.5 gap-2">
        <div class="flex rounded-[6px] w-full">
          <div
            ref="scenarioWrapper"
            class="scenario-wrapper w-full"
            :class="{
              extended: isExpanded,
            }"
          >
            <div class="scenario-box flex gap-2">
              <div
                class="flex items-center w-full justify-between gap-1.5 standard-elevation-0-dark py-1 px-1.5 rounded-[6px] text-color1 cursor-pointer"
                @click="isExpanded = !isExpanded"
              >
                <div class="flex flex-col">
                  <div class="caption-1 whitespace-nowrap flex">Szenario</div>
                  <h5 class="whitespace-nowrap max-w-60 truncate">
                    {{
                      `${project.title}: ` +
                      scenarios.find((item) => item.id === selectedScenarioId)
                        ?.title
                    }}
                  </h5>
                </div>
                <IconWrapper
                  icon="keyboard_arrow_down"
                  :class="[
                    {
                      'rotate-180': isExpanded,
                    },
                    'transform duration-300',
                  ]"
                />
              </div>
              <ButtonEl
                icon="close"
                color="color2"
                :edges="true"
                class="w-10 h-10 rounded-[6px]"
                @click="mapStore.toggleScenarioSelector"
              />
            </div>
            <div v-if="isExpanded" class="scenario-list-wrapper" @click.stop>
              <InputSearch
                class="rounded-[4px] overflow-hidden"
                placeholder="Suche"
                :search-keys="['title']"
                :items="scenarios"
                @update:search="filteredScenarios = $event"
              />
              <div
                class="flex flex-col gap-1.5 overflow-y-auto hide-scrollbar"
                :style="{ maxHeight: containerHeight - 202 + 'px' }"
              >
                <div
                  v-for="(item, index) in filteredScenarios"
                  :key="index"
                  class="rounded-[6px]"
                  :class="
                    scenarioHoverIndex === index
                      ? 'bg-active-area'
                      : 'bg-subtle'
                  "
                  @mouseenter="scenarioHoverIndex = index"
                >
                  <div class="px-2.5 py-1.5 flex flex-col items-start gap-1.5">
                    <div
                      class="flex flex-col gap-1 w-full -ml-1 -mb-0.5 cursor-pointer"
                    >
                      <VRadio
                        :model-value="isSelectedScenario(item)"
                        :density="null"
                        @click="selectScenario(item)"
                        @update:model-value="isSelectedScenario(item)"
                      >
                        <template #label>
                          <h6
                            class="flex text-title-color1 hover:text-button-primary-hover-color1 mb-[1px]"
                          >
                            {{ item.title }}
                          </h6>
                        </template>
                      </VRadio>
                    </div>
                    <div class="border-b border-line-neutral w-full"></div>
                    <div class="flex flex-col gap-1 w-full text-neutral">
                      <div class="flex flex-col subtitle-3 text-title-neutral">
                        <div>Szenariorahmen:</div>
                        <div>
                          {{ item.assumptions_scenario_title }}
                        </div>
                      </div>
                    </div>

                    <ButtonEl
                      v-if="scenarioHoverIndex === index"
                      size="s"
                      icon="ads_click"
                      text="Auswertung"
                      @click="navigateToDetail(project.id, item.id)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { ref } from 'vue';
import { useListeners } from '@/composables/useListeners';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import { useRouter } from 'vue-router';
import { useWindowSize } from '@vueuse/core';

const { height: containerHeight } = useWindowSize();

const props = defineProps({
  scenarios: Array,
  selectedScenarioId: Number,
  project: {
    type: Object,
    required: true,
  },
  mapStore: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['select-scenario']);

const router = useRouter();

const isExpanded = ref(false);
const scenarioWrapper = ref(null);
const filteredScenarios = ref(props.scenarios);
const scenarioHoverIndex = ref(
  props.scenarios.findIndex((item) => item.id === props.selectedScenarioId),
);

function selectScenario(scenario) {
  emit('select-scenario', scenario.id);
  isExpanded.value = false;
  filteredScenarios.value = props.scenarios;
}

function isSelectedScenario(item) {
  return item.id === props.selectedScenarioId;
}

useListeners({
  click: handleClickOutside,
  keydown: handleKeydown,
});

function handleClickOutside(event) {
  if (!scenarioWrapper.value?.contains(event.target)) {
    isExpanded.value = false;
  }
}

function handleKeydown(event) {
  if (
    event.key === 'Escape' &&
    !scenarioWrapper.value?.contains(event.target)
  ) {
    isExpanded.value = false;
  }
}

function navigateToDetail(projectId, scenarioId) {
  router.push({
    name: 'UseCase1ProjectResultOverview',
    params: { projectId, scenarioId },
  });
}
</script>

<style scoped lang="scss">
@import '@/assets/styles';

.outer-container {
  @apply absolute left-1/2 -top-1 transform -translate-x-1/2 min-w-[250px];
  @media (max-width: 1020px) {
    @apply top-12;
  }
}

.scenario-wrapper {
  display: flex;
  flex-direction: column;
  @apply rounded-[15px];

  .scenario-list-wrapper {
    @apply p-1.5 mt-2 pt-0 rounded-b-[8px] flex flex-col gap-1 w-full
    left-0 top-[46px] z-10;

    @apply absolute bg-white gap-[6px];
    @extend .standard-elevation-1;

    &::before {
      @apply absolute left-0 top-[-3px] w-full h-[3px] bg-white;
      content: '';
    }

    .scenario-item {
      @apply bg-subtle rounded-[3px] flex items-center justify-start p-2 min-w-max h-8
      cursor-pointer text-neutral;
      @extend .body-2;
    }
  }
}
</style>
