export const AQUAMARIN_00 = '#E7FDFA';
export const AQUAMARIN_20 = '#B6FDF6';
export const AQUAMARIN_30 = '#87F8EC';
export const AQUAMARIN_40 = '#6EEEDF';
export const AQUAMARIN_60 = '#1DC6B2';
export const AQUAMARIN_70 = '#119C8D';
export const LIND_GREEN_10 = '#E3F6D2';
export const LIND_GREEN_30 = '#B6DC8E';
export const LIND_GREEN_40 = '#9FCE6C';
export const LIND_GREEN_60 = '#70A638';
export const LIND_GREEN_70 = '#538327';
export const SUN_YELLOW_10 = '#FCEFBD';
export const SUN_YELLOW_30 = '#FEDE82';
export const SUN_YELLOW_40 = '#EDAF00';
export const SUN_YELLOW_70 = '#CA8A04';
export const IMPULSE_ORGANGE_LIGHT = '#FFD6A9';
export const IMPULSE_ORANGE_MEDIUM = '#FE8F11';
export const IMPULSE_ORGANGE_DARK = '#A85901';
export const TAROCCO_00 = '#FDF3F2';
export const TAROCCO_40 = '#FE7E58';
export const TAROCCO_60 = '#E2491B';
export const TAROCCO_70 = '#C73B12';
export const AZUR_BLUE_10 = '#BAE5FF';
export const AZUR_BLUE_20 = '#8FD4FF';
export const AZUR_BLUE_30 = '#66C2FF';
export const AZUR_BLUE_40 = '#3CAAF0';
export const AZUR_BLUE_60 = '#0278D4';
export const AZUR_BLUE_70 = '#0059B9';
export const DEEP_BLUE_LIGHT = '#6666FF';
export const DEEP_BLUE_MEDIUM = '#000099';
export const WARM_GREY_00 = '#F3EFEC';
export const WARM_GREY_40 = '#C3BCB6';
export const WARM_GREY_50 = '#B0A9A3';
export const NEUTRAL_GREY_00 = '#F2F2F2';
export const NEUTRAL_GREY_30 = '#D1D1D1';
export const NEUTRAL_GREY_60 = '#909090';
export const GREY_BEIGE_40 = '#E9CFAE';
export const GREY_BEIGE_70 = '#AD8653';
export const GREY_BEIGE_90 = '#604525';
export const BLACK = '#212121';

export const STATUS_CRITICAL = '#E20E00';
export const STATUS_WARNING = '#EDAF00';
export const STATUS_SUCCESS = '#70A638';

export const STATUS = {
  IN_OPERATION: STATUS_SUCCESS,
  SHUT_DOWN: STATUS_CRITICAL,
  REQUESTED: STATUS_WARNING,
  UNKNOWN: WARM_GREY_50,
};

export const SURFACE_SLOPE = {
  SUITABLE: STATUS_SUCCESS,
  UNKNOWN: WARM_GREY_50,
  PARTLY_SUITABLE: STATUS_WARNING,
};

export const GENERATION_POTENTIAL = {
  WATER: AQUAMARIN_60,
  WATER_MIN_GRADIENT: AQUAMARIN_00,
  WATER_MAX_GRADIENT: AQUAMARIN_70,
  WIND: AZUR_BLUE_40,
  WIND_MIN_GRADIENT: AZUR_BLUE_10,
  WIND_MAX_GRADIENT: AZUR_BLUE_60,
  BIOMASS: LIND_GREEN_40,
  BIOMASS_MIN_GRADIENT: LIND_GREEN_10,
  BIOMASS_MAX_GRADIENT: LIND_GREEN_40,
  PV_FREE: SUN_YELLOW_30,
  PV_FREE_MIN_GRADIENT: SUN_YELLOW_10,
  PV_FREE_MAX_GRADIENT: SUN_YELLOW_40,
  PV_ROOF: SUN_YELLOW_70,
};

export const GEOGRAPHICAL_LEVEL = {
  HEAT_DEMAND_MIN_GRADIENT: IMPULSE_ORGANGE_LIGHT,
  HEAT_DEMAND_MAX_GRADIENT: IMPULSE_ORANGE_MEDIUM,
  ELECTRICITY_DEMAND_MIN_GRADIENT: TAROCCO_00,
  ELECTRICITY_DEMAND_MAX_GRADIENT: TAROCCO_60,
};

export const BUILDINGS = {
  BUILDING: NEUTRAL_GREY_60,
  ELECTRICITY_DEMAND_MIN_GRADIENT: TAROCCO_00,
  ELECTRICITY_DEMAND_MAX_GRADIENT: TAROCCO_60,
  HEAT_DEMAND_MIN_GRADIENT: IMPULSE_ORGANGE_LIGHT,
  HEAT_DEMAND_MAX_GRADIENT: IMPULSE_ORANGE_MEDIUM,
};

export const SECTOR = {
  HOUSEHOLD: AZUR_BLUE_40,
  PUBLIC: LIND_GREEN_60,
  GHD: IMPULSE_ORANGE_MEDIUM,
  INDUSTRY: TAROCCO_60,
  MOBILITY: AQUAMARIN_60,
  UNKNOWN: WARM_GREY_40,
};

// TODO: Apply in infrastructure
export const INFRASTRUCTURE = {
  HIGH_VOLTAGE_NET: TAROCCO_70,
  TRANSFORMER_NET: TAROCCO_60,
  HEAT_LINE_MIN_GRADIENT: IMPULSE_ORGANGE_LIGHT,
  HEAT_LINE_MAX_GRADIENT: IMPULSE_ORANGE_MEDIUM,
  HEAT_FOCUS_AREAS: DEEP_BLUE_LIGHT,
};

export const SECTOR_BY_ENUM = {
  2: { color: SECTOR.HOUSEHOLD, label: 'Private Haushalte' },
  0: { color: SECTOR.GHD, label: 'Gewerbe, Handel,\nDienstleistungen' },
  4: { color: SECTOR.INDUSTRY, label: 'Industrie' },
  1: { color: SECTOR.PUBLIC, label: 'Öffentlich' },
  5: { color: SECTOR.MOBILITY, label: 'Mobilität' },
  3: { color: SECTOR.UNKNOWN, label: 'Sonstige' },
};

export const RENEWABLE_GENERATION = {
  WIND: AZUR_BLUE_20,
  PV_ROOF: SUN_YELLOW_70,
  PV_FREE: SUN_YELLOW_40,
  GEOTHERMAL: AQUAMARIN_30,
  BIOMASS: LIND_GREEN_30,
  HYDRO: AZUR_BLUE_40,
  TOTAL: IMPULSE_ORANGE_MEDIUM,
};

export const BALANCE = {
  POWER_DEMAND: DEEP_BLUE_MEDIUM,
  RENEWABLE_GENERATION: AQUAMARIN_60,
  CONVENTIONAL_GENERATION: IMPULSE_ORANGE_MEDIUM,
};

export const POWER_DEFAULT = {
  POWER_TARGET: DEEP_BLUE_MEDIUM,
  POWER_TODAY: AQUAMARIN_60,
  POWER_INCREASE: IMPULSE_ORANGE_MEDIUM,
};

export const AREA = {
  AVAILABLE: LIND_GREEN_40,
  REQUIRED: LIND_GREEN_70,
};

export const POWER_DEMAND = {
  CONVENTIONAL: SECTOR.HOUSEHOLD,
  ELECTRIFICATION_HEAT: AQUAMARIN_40,
  ELECTRIFICATION_HEAT_NETWORK: AQUAMARIN_20,
  ELECTRIFICATION_HEAT_SINGLE: AQUAMARIN_40,
  ELECTRIFICATION_MOBILITY: AQUAMARIN_60,
  EFFICIENCY: TAROCCO_40,
  TOTAL: DEEP_BLUE_MEDIUM,
};

export const POTENTIAL = DEEP_BLUE_MEDIUM;

export const CONSTRUCTION_YEAR = {
  UNTIL_1948: AZUR_BLUE_60,
  BETWEEN_1949_1968: LIND_GREEN_60,
  BETWEEN_1969_2001: TAROCCO_40,
  AFTER_2001: SUN_YELLOW_40,
  UNKNOWN: WARM_GREY_40,
};

export const CONSTRUCTION_YEAR_BY_ENUM = {
  0: { color: CONSTRUCTION_YEAR.UNTIL_1948, label: 'Vor 1949' },
  1: { color: CONSTRUCTION_YEAR.BETWEEN_1949_1968, label: '1949-1968' },
  2: { color: CONSTRUCTION_YEAR.BETWEEN_1969_2001, label: '1969-2001' },
  3: { color: CONSTRUCTION_YEAR.AFTER_2001, label: 'nach 2001' },
  999: { color: CONSTRUCTION_YEAR.UNKNOWN, label: 'unbekannt' },
};

export const HEATING_TYPE = {
  GAS: AZUR_BLUE_30,
  OIL: GREY_BEIGE_90,
  REN: AQUAMARIN_60,
  DIS: TAROCCO_40,
  COA: BLACK,
  WOD: GREY_BEIGE_40,
  ELE: SUN_YELLOW_40,
  BGA: LIND_GREEN_60,
  OTH: IMPULSE_ORANGE_MEDIUM,
  '-': NEUTRAL_GREY_00,
  UNK: NEUTRAL_GREY_60,
};

export const HEATING_TYPE_BY_ENUM = {
  GAS: { color: HEATING_TYPE.GAS, label: 'Fossiles Gas' },
  OIL: { color: HEATING_TYPE.OIL, label: 'Heizöl' },
  REN: { color: HEATING_TYPE.REN, label: 'Wärmepumpen,\nSolar-/Geothermie' },
  DIS: { color: HEATING_TYPE.DIS, label: 'Fernwärme' },
  COA: { color: HEATING_TYPE.COA, label: 'Kohle' },
  WOD: { color: HEATING_TYPE.WOD, label: 'Holz/Holzpellets' },
  ELE: { color: HEATING_TYPE.ELE, label: 'Strom\n(ohne Wärmepumpen)' },
  BGA: { color: HEATING_TYPE.BGA, label: 'Biomasse/Biogas' },
  OTH: { color: HEATING_TYPE.OTH, label: 'Sonstige' },
  '-': { color: HEATING_TYPE['-'], label: 'Keine Heizung' },
  UNK: { color: HEATING_TYPE.UNK, label: 'Unbekannt' },
};

export const SELF_SUFFICIENCY = {
  PRIMARY: LIND_GREEN_60,
  SECONDARY: WARM_GREY_00,
};

export const AREA_EVAL = {
  DEMAND_DEVELOPMENT: LIND_GREEN_40,
  TARGET_YEAR: IMPULSE_ORANGE_MEDIUM,
  STATUS_QUO: DEEP_BLUE_MEDIUM,
  CONVENTIONAL: AZUR_BLUE_60,
  E_MOBILITY: AQUAMARIN_60,
  ELECTRIFICATION_HEAT_NETWORK: AQUAMARIN_40,
  ELECTRIFICATION_HEAT_DEMAND: AQUAMARIN_20,
  WIND: AZUR_BLUE_20,
  PV_FREE: SUN_YELLOW_40,
  PV_ROOF: SUN_YELLOW_70,
  WATER: AZUR_BLUE_60,
  BIOMASS: LIND_GREEN_30,
};

// opacity is a value between 0 and 1
export function hexToRgba(hex, opacity) {
  hex = hex.replace('#', '');

  if (hex.length !== 6) {
    throw new Error('Invalid hex color');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
