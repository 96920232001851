<template>
  <main class="relative bg-subtle hide-scrollbar min-h-screen flex flex-col">
    <RouterView v-slot="{ Component, route: routerRoute }">
      <ErrorBoundary>
        <Suspense>
          <Transition :name="getTransitionName" mode="out-in">
            <template v-if="Component">
              <Component :is="currentLayout">
                <Component :is="Component" :key="routerRoute.fullPath" />
              </Component>
            </template>
          </Transition>
          <template #fallback>
            <FallbackContainer />
          </template>
        </Suspense>
      </ErrorBoundary>
    </RouterView>

    <FooterSection />

    <SnackBar />
  </main>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import cookie from 'vue-cookies';
import AuthLayout from '@/layouts/AuthLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useTitle } from '@vueuse/core';
import MapLayout from '@/layouts/MapLayout.vue';
import FooterSection from '@/components/footer/FooterSection.vue';
import SnackBar from '@/components/snack-bar/SnackBar.vue';
import FallbackContainer from '@/components/fallback-container/FallbackContainer.vue';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';
import PlainLayout from '@/layouts/PlainLayout.vue';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
cookie.set('django_timezone', clientTimeZone);

const route = useRoute();

const { getTransitionName } = usePageTransitionStore();

const currentLayout = computed(() => {
  switch (route.meta.layout) {
    case 'Auth':
      return AuthLayout;
    case 'Map':
      return MapLayout;
    case 'Plain':
      return PlainLayout;
    default:
      return DefaultLayout;
  }
});

const title = useTitle('Default Title');

watch(
  () => route.meta.title,
  (newTitle) => {
    if (newTitle) {
      title.value = 'PadE | ' + newTitle;
    } else {
      title.value = 'PadE';
    }
  },
  { immediate: true },
);
</script>
