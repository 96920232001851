<template>
  <ButtonEl
    icon="map"
    :icon-right="true"
    color="color2"
    class="rounded-[4px] w-10 h-10"
    @click="navigateToMap()"
  />
</template>
<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: [String, Number],
    required: true,
  },
  mapStore: {
    type: Object,
    required: true,
  },
  mapLayerConfig: {
    type: Object,
    required: true,
  },
  aggregationLevel: {
    type: String || null,
    default: null,
  },
  text: {
    type: String,
    default: '',
  },
});

function navigateToMap() {
  props.mapStore.configureInitialMapState(props.mapLayerConfig);
  if (props.aggregationLevel !== null) {
    props.mapStore.setAggregationLevel(props.aggregationLevel);
  }
  router.push({
    name: 'mapView1',
    params: { projectId: props.projectId, scenarioId: props.scenarioId },
  });
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles';

.gradient-box {
  border-radius: 8px;
  height: 33px;
  background: linear-gradient(to right, #f5f0ed, #676665);
}

.chart-item {
  @extend .standard-elevation-0-dark;
  @apply rounded-[8px];
}
</style>
