import * as Sentry from '@sentry/vue';

export function initSentry(app, router) {
  if (!window.location.hostname.split('.').includes('localhost')) {
    const dsn =
      'https://b949c0ab0bc7b4433dd4c10c060b0b95@o1327264.ingest.us.sentry.io/4507339385143296';
    let tracePropagationTargets = ['localhost'];
    const environment = window.location.hostname.includes('stage')
      ? 'stage'
      : 'production';
    if (environment === 'prod') {
      tracePropagationTargets.push(/^https:\/\/wp\.lbd\.network/);
    } else if (environment === 'stage') {
      tracePropagationTargets.push(/^https:\/\/wp-stage\.lbd\.network/);
    }
    const sampleRate = environment === 'stage' ? 1.0 : 0.1;

    Sentry.init({
      app,
      environment,
      dsn,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: sampleRate,
      tracePropagationTargets,
      replaysSessionSampleRate: sampleRate,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
