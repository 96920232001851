<template>
  <TopHeaderBar class="justify-start">
    <div class="flex items-center gap-2.5">
      <IconWrapper :size="48" :icon="IconZahnrad" fill="text-core-color2" />
      <h1 class="text-title-neutral">Allgemeine Annahmen</h1>
    </div>
  </TopHeaderBar>
  <ParameterSetView
    title="Allgemeine Annahmen"
    :icon="IconZahnrad"
    :url="'/api/usecase-1/assumptions-general/'"
    :release-option="false"
    :exclude-fields="['created_by']"
    :enable-release="false"
    :is-read-only="(_) => !userStore.uc1CanChangeAssumptionsGeneral"
    :table-headers="tableHeaders"
    :is-single-instance="true"
    :content-structure="contentStructureAssumptionsGeneral"
  />
</template>

<script setup>
import ParameterSetView from '@/apps/features/parameter-set/ParameterSetView.vue';
import { contentStructureAssumptionsGeneral } from '@/apps/usecase-1/assumptions-general/contentStructureAssumptionsGeneral';
import IconZahnrad from '@/assets/icons/custom/misc/IconZahnrad.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import { useUsers } from '@/apps/auth/users-store';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';

const userStore = useUsers();

const tableHeaders = [
  {
    title: 'Erstellt am',
    key: 'date',
    sortable: false,
    width: '125px',
  },
  {
    title: 'Aktionen',
    key: 'functions',
    align: 'end',
    sortable: false,
    width: '125px',
  },
];
</script>
