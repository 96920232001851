import building from './buildings';
import generationPotential from './generation-potential';
import geographicalLevel from './geographical-level';
import estates from './estates';
import infrastructure from './infrastructure';
import markerIcon from '@/assets/img/mapIcons/marker.png';

export default {
  ...geographicalLevel,
  ...generationPotential,
  ...estates,
  ...infrastructure,
  ...building,
  marker: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      geoJSONSource: true,
      type: 'symbol',
      id: 'markerSource',
      layerId: 'marker',
      visibility: 'visible',
      dependsOnScenario: false,
      icons: [
        {
          name: 'markerIcon',
          path: markerIcon,
        },
      ],
    },
    style: {
      default: {
        layout: {
          'icon-image': 'markerIcon',
          'icon-size': 0.1,
          'icon-offset': [0, -165],
        },
      },
    },
  },
};
