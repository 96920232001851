<template>
  <TopHeaderBar class="justify-start">
    <PotentialAnalysisCreateDialog
      :potentials-data
      @create-analysis="toggleForm"
  /></TopHeaderBar>

  <div class="flex items-center gap-2.5">
    <IconWrapper
      v-if="IconAnalysis"
      :size="48"
      :icon="IconAnalysis"
      fill="text-core-color2"
    />
    <h1 class="text-title-neutral">Potenzialanalyse</h1>
  </div>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-5">
      <VuetifyDataTable
        :headers="tableHeadersPotentials"
        :item-data="sortedPotentialsData"
        :fixed-header="true"
        :substract-height="426"
        :show-expand="false"
      >
        <template #created_at="{ item }">
          {{
            new Date(item.created_at).toLocaleDateString('de-DE') +
            ' von ' +
            item.created_by
          }}
        </template>
        <template #progress>
          <div class="flex justify-center items-center">
            <IconWrapper
              :icon="true ? 'task_alt' : IconTaskProgressSharp"
              :fill="true ? 'text-spot-success' : 'text-spot-warning'"
            />
          </div>
        </template>
        <template #functions="{ item }">
          <div class="flex items-center justify-end gap-2">
            <DeleteDialog
              :id="item.id"
              :name-deleted-item="item.title"
              :dto="PotentialAnalysisDto"
              @instance-removed="fetchPotentials"
            >
              <IconWrapper
                fill="text-core-color1"
                hover="hover:text-button-primary-hover-color1"
                icon="delete_forever"
                class="cursor-pointer"
                type="round"
              />
            </DeleteDialog>
            <IconWrapper
              icon="segment"
              fill="text-core-color1"
              hover="hover:text-button-primary-hover-color1"
              class="cursor-pointer"
              @click="toggleForm(item.id)"
            />
          </div>
        </template>
      </VuetifyDataTable>
    </div>
    <div
      v-if="showForm"
      class="bg-white rounded-[8px] standard-elevation-0-dark p-5 flex flex-col gap-5"
    >
      <div class="flex justify-between items-center gap-5 min-h-[48px]">
        <h3 class="text-title-neutral">Konfiguration Potenzialanalyse</h3>
        <div class="flex items-center gap-5">
          <ButtonEl
            v-if="!readOnly"
            text="Berechnung starten"
            icon="table_view"
            :disabled="!formIsValid || !titleIsUnique"
            :is-loading="loading"
            @click="create"
          />
          <IconWrapper
            icon="close"
            class="cursor-pointer"
            fill="text-core-color1"
            @click="closeForm"
          />
        </div>
      </div>
      <PotentialAnalysisTabsWrapper
        ref="formTabsEl"
        :potentials-data
        @update:is-valid="formIsValid = $event"
        @update:read-only="readOnly = $event"
        @update:title-is-unique="titleIsUnique = $event"
        @update:potential-analysis="potentialAnalysis = $event"
      />
    </div>
  </div>
</template>

<script setup>
import { tableHeadersPotentials } from '@/apps/usecase-2/potential-analysis/potential-analysis-data';
import { PotentialAnalysisDto } from '@/apps/usecase-2/potential-analysis/potential-analysis-dto';
import IconAnalysis from '@/assets/icons/custom/misc/IconAnalysis.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { restFetchAll, restPost } from '@/utils/rest-utils';
import { computed, ref } from 'vue';
import { useToastStore } from '@/stores/toast-store';
import { useRestrictionsStore } from './stores/potentialAnalysisRestrictionsStore';
import PotentialAnalysisCreateDialog from './components/PotentialAnalysisCreateDialog.vue';
import PotentialAnalysisTabsWrapper from './components/PotentialAnalysisTabsWrapper.vue';
import { nextTick } from 'vue';

const { showToast } = useToastStore();
const showForm = ref(false);
const loading = ref(false);
const potentialAnalysis = ref(null);
const potentialsData = ref([]);
const formIsValid = ref(false);
const titleIsUnique = ref(true);
const readOnly = ref(false);
const formTabsEl = ref(null);

await fetchPotentials();
await useBaseStore().fetchSimplifiedMuniData();
await useRestrictionsStore().fetchRestrictions();

async function toggleForm(id = null, title = null) {
  showForm.value = true;
  await nextTick();
  if (formTabsEl.value) {
    await formTabsEl.value.fillForm(id, title);
  }
}

async function fetchPotentials() {
  potentialsData.value = await restFetchAll(PotentialAnalysisDto);
}

async function create() {
  loading.value = true;
  let fieldsToPost = [
    'title',
    'description',
    'municipalities',
    'min_area_m2',
    'max_count_parcels',
    'orientation_value_greater_equal_than',
    'orientation_value_smaller_equal_than',
    'slope_degree_value_greater_equal_than',
    'slope_degree_value_smaller_equal_than',
    'max_load_for_grid_connection_point_kw',
    'max_load_for_substation_kw',
    'max_load_for_high_voltage_kw',
    'connection_radius_m',
    'battery_capacity_proportion',
    'routing_option',
    'disadvantage_area_list',
    'agricultural_plots_list',
    'specific_plant_size_kw_m2',
    'dc_ac_ratio',
    'inverter_efficiency',
    'performance_ratio',
    'mounting_slope_degree',
    'configrestrictions_set',
  ];
  if (potentialAnalysis.value.cluster_apply_analysis) {
    fieldsToPost = [
      ...fieldsToPost,
      'cluster_apply_analysis',
      'cluster_transformer_load_max_kw',
      'cluster_connected_load_min_kw',
      'cluster_connected_load_max_kw',
      'cluster_consider_battery',
      'cluster_consider_only_no_capacity',
      'cluster_number_project_max_m',
      'cluster_distance_grid_max_m',
      'cluster_distance_projects_max',
      'cluster_consider_external_projects',
    ];
  }
  let message;
  let color;
  restPost(potentialAnalysis.value, fieldsToPost)
    .then(async () => {
      await fetchPotentials();
      showForm.value = false;
      message = 'Analyse wird erstellt. Dies kann einige Zeit dauern.';
      color = 'success';
    })
    .catch(() => {
      message =
        'Ein Fehler ist aufgetreten, bitte überprüfen Sie ihre' +
        ' Eingaben oder wenden Sie sich an den Support.';
      color = 'error';
    })
    .finally(() => {
      showToast({
        color,
        message,
      });
      loading.value = false;
    });
}

function closeForm() {
  showForm.value = false;
}

const sortedPotentialsData = computed(() => {
  return [...potentialsData.value].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
});
</script>
