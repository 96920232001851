<template>
  <div class="flex flex-col gap-5">
    <ToolTip>
      <template #text>
        Der Werthaltigkeitsindikator gibt auf Basis von sechs zu
        parametrierenden Kriterien an, wie werthaltig ein Fläche gegenüber
        anderen Flächen ist. Der Werthaltigkeitsindikator ist als
        Priorisierungskriterium zu verstehen.
      </template>
    </ToolTip>
    <div class="weighting-item">
      <InputEl
        v-model="weightingDto.minimum_area_m2"
        :label="weightingMetaData.minimum_area_m2.label"
        :input-type="getFieldType(weightingMetaData.minimum_area_m2)"
        placeholder="Bitte eingeben"
        :suffix="weightingMetaData.minimum_area_m2.unit"
        class="w-full"
        :rules="getValidationRules(weightingFormRules.minimum_area_m2)"
      />
      <div class="flex gap-5 relative">
        <div
          class="body-2 text-title-neutral absolute top-[-52px] right-[290px]"
        >
          Gewichtungsfaktor
        </div>
        <SliderParameterization
          v-model="weightingDto.weighting_minimum_area"
          color="#0059B9"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
        />
        <SquareMeasureWeighting v-model="weightingDto.weighting_minimum_area" />
      </div>
    </div>
    <div class="weighting-item">
      <div class="flex gap-2.5">
        <InputEl
          v-model="weightingDto.form_factor_width_m"
          :label="weightingMetaData.form_factor_width_m.label"
          :input-type="getFieldType(weightingMetaData.form_factor_width_m)"
          placeholder="Bitte eingeben"
          :suffix="weightingMetaData.form_factor_width_m.unit"
          class="w-1/2"
          :rules="getValidationRules(weightingFormRules.form_factor_width_m)"
        />
        <InputEl
          v-model="weightingDto.area_share_bbox"
          :label="weightingMetaData.area_share_bbox.label"
          :input-type="getFieldType(weightingMetaData.area_share_bbox)"
          placeholder="Bitte eingeben"
          :suffix="weightingMetaData.area_share_bbox.unit"
          class="w-1/2"
          :rules="getValidationRules(weightingFormRules.area_share_bbox)"
        />
      </div>
      <div class="flex gap-5">
        <SliderParameterization
          v-model="weightingDto.weighting_form_factors"
          color="#0059B9"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
        />
        <SquareMeasureWeighting v-model="weightingDto.weighting_form_factors" />
      </div>
    </div>
    <div class="weighting-item">
      <InputEl
        v-model="weightingDto.area_share_eligible"
        :label="weightingMetaData.area_share_eligible.label"
        :input-type="getFieldType(weightingMetaData.area_share_eligible)"
        placeholder="Bitte eingeben"
        :suffix="weightingMetaData.area_share_eligible.unit"
        class="w-full"
        :rules="getValidationRules(weightingFormRules.area_share_eligible)"
      />
      <div class="flex gap-5">
        <SliderParameterization
          v-model="weightingDto.weighting_share_eligible"
          color="#0059B9"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
        />
        <SquareMeasureWeighting
          v-model="weightingDto.weighting_share_eligible"
        />
      </div>
    </div>
    <div class="weighting-item">
      <InputEl
        v-model="weightingDto.invest_cost_maximum"
        :label="weightingMetaData.invest_cost_maximum.label"
        :input-type="getFieldType(weightingMetaData.invest_cost_maximum)"
        placeholder="Bitte eingeben"
        :suffix="weightingMetaData.invest_cost_maximum.unit"
        class="w-full"
        :rules="getValidationRules(weightingFormRules.invest_cost_maximum)"
      />
      <div class="flex gap-5">
        <SliderParameterization
          v-model="weightingDto.weighting_invest_cost_maximum"
          color="#0059B9"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
        />
        <SquareMeasureWeighting
          v-model="weightingDto.weighting_invest_cost_maximum"
        />
      </div>
    </div>
    <div class="weighting-item items-center">
      <CheckboxEl :model-value="true" :disabled="true">
        <span>Verfügbarkeit Umspannwerk</span>
      </CheckboxEl>
      <div class="flex gap-5">
        <SliderParameterization
          v-model="weightingDto.weighting_availability_grid_station"
          color="#0059B9"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
        />
        <SquareMeasureWeighting
          v-model="weightingDto.weighting_availability_grid_station"
        />
      </div>
    </div>
    <div class="weighting-item">
      <InputEl
        v-model="weightingDto.area_share_field"
        :label="weightingMetaData.area_share_field.label"
        :input-type="getFieldType(weightingMetaData.area_share_field)"
        placeholder="Bitte eingeben"
        :suffix="weightingMetaData.area_share_field.unit"
        class="w-full"
        :rules="getValidationRules(weightingFormRules.area_share_field)"
      />
      <div class="flex gap-5">
        <SliderParameterization
          v-model="weightingDto.weighting_share_field"
          color="#0059B9"
          :step="1"
          :enabled-max="6"
          :enabled-min="0"
        />
        <SquareMeasureWeighting v-model="weightingDto.weighting_share_field" />
      </div>
    </div>
    <div class="flex justify-between gap-5 pr-5">
      <ButtonEl
        icon="balance"
        color="color2"
        text="Alle gleich gewichten"
        class="w-fit"
        @click="setSameWeight()"
      />
      <div class="flex gap-2.5 items-center">
        <span class="subtitle-2 text-color1">Summe</span>
        <SquareMeasureWeighting v-model="sumWeighting" variant="secondary" />
      </div>
    </div>
    <div class="flex gap-5">
      <ButtonEl
        icon="save"
        icon-fill="text-inverted-color1"
        text="Speichern"
        class="w-fit"
        @click="saveDialog = true"
      />
      <ButtonEl
        icon="lock_reset"
        color="color2"
        variant="secondary"
        text="Zurücksetzen auf Default"
        class="w-fit"
        @click="console.log('define usecase')"
      />
    </div>
    <VuetifyDialog
      v-if="saveDialog"
      v-model="saveDialog"
      title="Hinweis"
      :persistent="true"
      width="620px"
    >
      <template #content>
        Die angepassten Einstellungen wirken sich auf alle bestehenden und neuen
        Projekte aus.
      </template>
      <template #actions>
        <ButtonEl text="Abbrechen" color="color2" @click="saveDialog = false" />
        <ButtonEl text="Speichern" @click="save()" />
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import SliderParameterization from '@/apps/features/slider/SliderParameterization.vue';
import { WeightingValuesDto } from '@/apps/usecase-2/settings/weighting-values/weighting-values-dto';
import ButtonEl from '@/components/button/ButtonEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import InputEl from '@/components/input/InputEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import {
  convertMetaDataToFormRules,
  getFieldType,
  getValidationRules,
  getWritableFields,
  restFetchAll,
  restGetOrFetchMetaData,
  restPatch,
  restPost,
} from '@/utils/rest-utils';
import { sum } from 'lodash';
import { computed, ref } from 'vue';
import SquareMeasureWeighting from './components/SquareMeasureWeighting.vue';
import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();

const weightingMetaData = await restGetOrFetchMetaData(WeightingValuesDto);
const weightingFormRules = convertMetaDataToFormRules(weightingMetaData);
const weightingItemData = await restFetchAll(WeightingValuesDto);

const weightingDto = ref(new WeightingValuesDto());
const itemExists = computed(() => weightingItemData.length > 0);
// if instance exists set form fields accordingly in dto
if (itemExists.value) weightingDto.value = weightingItemData[0];
const sumWeighting = computed(() =>
  sum([
    weightingDto.value.weighting_minimum_area,
    weightingDto.value.weighting_form_factors,
    weightingDto.value.weighting_share_eligible,
    weightingDto.value.weighting_invest_cost_maximum,
    weightingDto.value.weighting_availability_grid_station,
    weightingDto.value.weighting_share_field,
  ]),
);

const saveDialog = ref(false);

function save() {
  if (itemExists.value) {
    restPatch(WeightingValuesDto, weightingDto.value.id, weightingDto.value)
      .then(() => showSuccessToast())
      .catch((err) => showErrorToast());
  } else {
    restPost(weightingDto.value, getWritableFields(weightingMetaData))
      .then(() => showSuccessToast())
      .catch((err) => showErrorToast());
  }
  saveDialog.value = false;
}

function setSameWeight() {
  weightingDto.value.weighting_minimum_area = 1;
  weightingDto.value.weighting_form_factors = 1;
  weightingDto.value.weighting_share_eligible = 1;
  weightingDto.value.weighting_invest_cost_maximum = 1;
  weightingDto.value.weighting_availability_grid_station = 1;
  weightingDto.value.weighting_share_field = 1;
}

function showSuccessToast() {
  showToast({
    color: 'success',
    message: 'erfolgreich gespeichert',
  });
}

function showErrorToast() {
  showToast({
    color: 'error',
    message: 'Fehlgeschlagen, bitte überprüfen Sie ihre Eingaben.',
  });
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles';
.weighting-item {
  @apply grid grid-cols-2 gap-5 p-5 items-end;
  @extend .standard-elevation-0-dark;
}
</style>
