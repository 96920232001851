<template>
  <div
    v-auto-animate
    class="standard-elevation-0-dark rounded-[12px] p-5 flex flex-col gap-2.5"
    :class="{ 'outline outline-error': error }"
  >
    <div class="flex flex-col gap-4">
      <h5 class="text-color1">{{ title }}</h5>
      <div class="body-2 text-neutral">{{ subTitle }}</div>
    </div>
    <h6 v-if="error" class="text-error">
      {{ error }}
    </h6>
    <div v-auto-animate class="flex justify-start gap-5">
      <div
        v-for="(interval, index) in intervals"
        :key="index"
        class="flex flex-col gap-2.5"
      >
        <div class="flex justify-start gap-1.5">
          <InputEl
            v-model="interval.from"
            :disabled="index === 0"
            label="von"
            input-type="float"
            placeholder="Bitte eingeben"
            suffix="kW"
            class="w-full"
            @update:model-value="!interval.from && (interval.from = 0)"
          />
          <InputEl
            :placeholder="
              index === intervals.length - 1
                ? infinity
                : intervals[index + 1].from?.toLocaleString('de-DE')
            "
            label="bis kleiner"
            :input-type="'string'"
            disabled
            suffix="kW"
            class="w-full"
          />
        </div>
        <div v-for="value in interval.values" :key="value.index">
          <span v-if="index === 0">{{ value.title }}</span>
          <InputEl
            v-model="value.value"
            :label="`${interval.from.toLocaleString('de-DE')} - ${interval.to.toLocaleString('de-DE')} kW`"
            input-type="float"
            placeholder="Bitte eingeben"
            :suffix="value.unit"
            class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-end gap-2.5 pt-[14px]">
        <ButtonEl
          v-if="showRemoveButton"
          icon="remove"
          color="color2"
          variant="secondary"
          class="w-10 h-10"
          @click="removeInterval"
        />
        <ButtonEl
          v-if="showAddButton"
          icon="add"
          color="color2"
          variant="secondary"
          class="w-10 h-10"
          @click="addInterval"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { validateIntervals } from '@/apps/usecase-2/settings/invest-costs/utils';
import ButtonEl from '@/components/button/ButtonEl.vue';
import InputEl from '@/components/input/InputEl.vue';
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['add-interval', 'remove-interval', 'update-error']);

defineExpose({ createIntervalInstance });

const props = defineProps({
  title: {
    type: String,
    default: 'title',
  },
  subTitle: {
    type: String,
    default: 'Eigene Leistungsbereiche (Anschlussleistung) definieren',
  },
  createValue: {
    type: Object,
    required: true,
  },
  defaultValue: {
    type: [Number, String],
    required: true,
  },
  maxIntervals: {
    type: Number,
    default: 5,
  },
  intervals: {
    type: Array,
    required: true,
  },
  stepsByLength: {
    type: Object,
    default: () => ({
      1: 1000,
      2: 10000,
      3: 20000,
      4: 40000,
    }),
  },
});

const showAddButton = computed(
  () => props.intervals.length < props.maxIntervals,
);
const showRemoveButton = computed(() => props.intervals.length > 1);
const infinity = ref('∞');
const error = ref();

// Watch for changes in intervals and validate
watch(
  () => props.intervals,
  (newIntervals) => {
    error.value = validateIntervals(newIntervals);
    emit('update-error', error.value);
  },
  { deep: true },
);

/**
 * Creates a new interval instance with the specified parameters.
 * @param {number} from - The starting point of the interval.
 * @param {number|string} to - The endpoint of the interval, typically '∞'.
 * @param {number|string} [value=props.defaultValue] - The value for the interval (defaults to props.defaultValue).
 * @returns {Object} - The created interval instance containing the title, from, to, and values.
 */
function createIntervalInstance(from, to, value = props.defaultValue) {
  return {
    title: '',
    from,
    to,
    values: [
      {
        ...props.createValue,
        value,
      },
    ],
  };
}

/**
 * Adds a new interval to the intervals array and emits the corresponding event.
 * The new interval's starting value is calculated based on existing intervals.
 */
function addInterval() {
  const countIntervals = props.intervals.length;
  const startValue = Math.max(
    props.stepsByLength[countIntervals],
    countIntervals > 1 ? props.intervals[countIntervals - 1].from + 1 : 0,
  );
  emit('add-interval', {
    startValue,
    interval: createIntervalInstance(startValue, '∞'),
  });
}

/**
 * Removes the last interval from the intervals array and emits the corresponding event.
 */
function removeInterval() {
  emit('remove-interval');
}
</script>
