<template>
  <div class="flex flex-col gap-5">
    <skeleton-loader-list v-if="!isLoaded" />
    <div v-else class="flex flex-col gap-5">
      <ParameterSetList
        v-if="!isSingleInstance"
        :instances="instances"
        :url="url"
        :is-read-only="isReadOnly"
        :enable-delete="enableDelete"
        :is-released
        :table-headers="tableHeaders"
        @edit-instance="toggleInstance"
        @update-instances="fetchInstances"
        @abort="abort"
      />
      <template v-if="showCreateButton && canCreateInstance">
        <ParameterSetViewCreateDialog
          v-if="createDialog && instances.length > 0"
          :scenario-frames="instances"
          @copy-instance="toggleInstance($event.id, $event.title, true)"
          @create-instance="createInstance"
        />
        <ButtonEl
          v-else
          icon="add"
          icon-fill="text-inverted-color1"
          :text="createButtonText"
          class="w-fit"
          @click="createInstance"
        />
      </template>
      <ParameterSetForm
        v-if="showForm"
        :instance-editing="instanceEditing"
        :meta-data="metaData"
        :form-tabs-key
        :url="url"
        :instances-names="instancesNames"
        :exclude-fields="excludeFields"
        :enable-release="enableRelease"
        :form-title="formTitle"
        :form-max-height-is-height
        :read-only="readOnly || isReadOnly(instanceEditing)"
        :clone-existing-instance="cloneExistingInstance"
        :is-single-instance="isSingleInstance"
        :exclude-from-request-if-null="excludeFromRequestIfNull"
        :content-structure="contentStructure"
        @update-instances="fetchInstances"
        @abort="abort"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import axios from '@/utils/axiosHelper';
import SkeletonLoaderList from '@/components/skeleton-loader/skeletonLoaderList.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import ParameterSetList from './ParameterSetList.vue';
import ParameterSetForm from './ParameterSetForm.vue';
import { useGoBack } from '@/composables/useGoBack';
import ParameterSetViewCreateDialog from '@/apps/features/parameter-set/ParameterSetViewCreateDialog.vue';
import { cloneDeep } from 'lodash';

const props = defineProps({
  title: {
    type: String,
    default: 'Parameter',
  },
  isSingleInstance: {
    type: Boolean,
    default: false,
  },
  isReadOnly: {
    type: Function,
    default: () => false,
  },
  isReleased: {
    type: Function,
    default: () => true,
  },
  enableDelete: {
    type: Function,
    default: () => false,
  },
  url: {
    type: String,
    required: true,
  },
  identifier: {
    type: String,
    default: 'id',
  },
  excludeFields: {
    type: Array,
    default: () => [],
  },
  excludeFromRequestIfNull: {
    type: Array,
    default: () => [],
  },
  createButtonText: {
    type: String,
    default: null,
  },
  createDialog: {
    type: Boolean,
    default: false,
  },
  enableRelease: {
    type: Boolean,
    default: false,
  },
  canCreateInstance: {
    type: Boolean,
    default: false,
  },
  formTitle: {
    type: String,
    default: null,
  },
  tableHeaders: {
    type: Array,
  },
  contentStructure: {
    type: Array,
    default: () => [],
  },
  formMaxHeightIsHeight: {
    type: Boolean,
    default: false,
  },
});
const goBack = useGoBack();

const metaData = ref(null);
const instances = ref([]);
const isLoaded = ref(false);
const instanceEditing = ref(null);
const showForm = ref(false);
const readOnly = ref(false);
const cloneExistingInstance = ref(false);

onMounted(async () => {
  await fetchMetaData();
  await fetchInstances();
  isLoaded.value = true;
  // Show from and load instance to edit if is Single and instance exists
  if (props.isSingleInstance) {
    showForm.value = true;
    if (instances.value.length) instanceEditing.value = instances.value[0];
  }
});

async function fetchInstances(newInstanceId = null) {
  const response = await axios({ url: props.url, method: 'GET' });
  instances.value = response.data.results;
  cloneExistingInstance.value = false;

  if (newInstanceId) {
    instanceEditing.value.id = newInstanceId;
  }

  await nextTick(() => {
    window.scrollTo(0, document.body.scrollHeight);
  });
}

async function fetchMetaData() {
  const response = await axios({ url: props.url, method: 'OPTIONS' });
  metaData.value = response.data.actions.POST;
}

const showCreateButton = computed(() => {
  return (
    !props.isSingleInstance && !showForm.value && instanceEditing.value === null
  );
});

const instancesNames = computed(() => {
  return instances.value.map((instance) => instance.title);
});

function createInstance() {
  abort();
  showForm.value = true;
  if (props.isSingleInstance) {
    goBack();
  }
}

const formTabsKey = ref(0);

function toggleInstance(instanceId, title = null, useExistingInstance = false) {
  abort();

  const instance = instances.value.find(
    (instance) => instance[props.identifier] === instanceId,
  );

  if (!instance) return; // Early return if instance is not found

  instanceEditing.value = cloneDeep(instance);

  if (useExistingInstance) {
    instanceEditing.value.title = title;
    cloneExistingInstance.value = true;
    instanceEditing.value.is_released = false;
  }

  readOnly.value =
    !useExistingInstance && props.isReadOnly(instanceEditing.value);

  showForm.value = true;
  formTabsKey.value++;
}

function abort() {
  showForm.value = false;
  readOnly.value = false;
  instanceEditing.value = null;
  if (props.isSingleInstance) {
    goBack();
  }
}

watch(showForm, (value) => {
  if (value) {
    nextTick(() => {
      const form = document.querySelector('.parameter-set-form');
      form.scrollIntoView({ behavior: 'smooth' });
    });
  }
});
</script>
