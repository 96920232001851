<template>
  <div class="flex flex-col gap-5">
    <h3 class="text-title-neutral">EE-Bestand vs. -Potenzial</h3>
    <div class="flex justify-between gap-5">
      <div class="relative min-h-[600px] w-full">
        <BarChartVerticalSharedYAxis
          :series="series"
          :labels="labels"
          :max-x-axis="maxXAxis"
          :allow-tooltip-callback="getAllowTooltips()"
          title-left="Bestand in MW(el)"
          title-right="Potenzial in MW(el)"
          label-unit="MW"
        />
        <div class="absolute left-3 bottom-3 whitespace-nowrap body-2">
          * Eine Ausweisung von Potenzialen ist auf dieser Ebene nicht möglich.
        </div>
      </div>
      <div class="flex flex-col items-center justify-center gap-8 px-5">
        <div
          v-for="item in totalValuesRight"
          :key="item"
          class="flex flex-col items-center gap-2.5"
        >
          <h3 class="text-[#322A26] whitespace-nowrap">{{ item.title }}</h3>
          <h1 :style="{ color: item.color }">
            {{
              item.value.toLocaleString('de-DE', { maximumFractionDigits: 0 }) +
              ' ' +
              item.unit
            }}
          </h1>
        </div>
        <div class="flex flex-col items-center gap-2.5">
          <div class="body-2 text-center">
            EE-Bestand/-Potenziale auf Karte betrachten
          </div>
          <to-map-button
            :project-id
            :scenario-id
            :map-store
            :text="'zur Kartenansicht'"
            :map-layer-config="[
              { layer: LAYER_KEY__GENERATION_WIND, state: 'default' },
              { layer: LAYER_KEY__GENERATION_HYDROPOWER, state: 'default' },
              { layer: LAYER_KEY__GENERATION_PV_FREE, state: 'default' },
              { layer: LAYER_KEY__GENERATION_BIOMASS, state: 'default' },
              { layer: LAYER_KEY__POTENTIAL_WIND, state: 'default' },
              { layer: LAYER_KEY__POTENTIAL_PV_FREE, state: 'default' },
              { layer: LAYER_KEY__BUILDINGS, state: 'potentialPvRoofKw' },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
  <SourcesItem item="renewable-generation" />
</template>

<script setup>
import BarChartVerticalSharedYAxis from '@/components/charts/BarChartVerticalSharedYAxis.vue';
import { computed, ref } from 'vue';
import { RENEWABLE_GENERATION } from '@/configs/color-constants';
import ToMapButton from '../../../features/to-map-button/ToMapButton.vue';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import { useProjectResult } from './project-result-store';
import { useMapStore } from '../../map/map-store';
import { useRouter } from 'vue-router';
import {
  LAYER_KEY__GENERATION_BIOMASS,
  LAYER_KEY__GENERATION_HYDROPOWER,
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_WIND,
  LAYER_KEY__POTENTIAL_PV_FREE,
  LAYER_KEY__POTENTIAL_WIND,
} from '../../map/layer-config/generation-potential';
import { LAYER_KEY__BUILDINGS } from '../../map/layer-config/buildings';

const router = useRouter();
const mapStore = useMapStore();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const resultStore = useProjectResult();
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);

// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

const totalValuesRight = ref([
  {
    title: 'Bestand Gesamt',
    value: resultStore.getGenerationTotalKw / 1000,
    unit: 'MW',
    color: '#7E7570',
  },
  {
    title: 'Potenzial Gesamt',
    value: resultStore.getPotentialTotalKw / 1000,
    unit: 'MW',
    color: '#538327',
  },
]);

const maxXAxis = computed(
  () =>
    Math.ceil(
      Math.max(
        resultStore.getPotentialByTechnologyKw.wind / 1000,
        resultStore.getPotentialByTechnologyKw.pvRoof / 1000,
        resultStore.getPotentialByTechnologyKw.pvFree / 1000,
        resultStore.getGenerationByTechnologyKw.wind / 1000,
        resultStore.getGenerationByTechnologyKw.pvRoof / 1000,
        resultStore.getGenerationByTechnologyKw.pvFree / 1000,
        resultStore.getGenerationByTechnologyKw.biomass / 1000,
        resultStore.getGenerationByTechnologyKw.hydroPower / 1000,
      ) / 100,
    ) * 100,
);

const series = ref([
  {
    name: 'Potenzial',
    type: 'bar',
    barWidth: 25,
    xAxisIndex: 1,
    yAxisIndex: 1,
    data: [
      { value: 0, itemStyle: { color: RENEWABLE_GENERATION.HYDRO } },
      { value: 0, itemStyle: { color: RENEWABLE_GENERATION.BIOMASS } },
      {
        value: resultStore.getPotentialByTechnologyKw.wind / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.WIND },
      },
      {
        value: resultStore.getPotentialByTechnologyKw.pvFree / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.PV_FREE },
      },
      {
        value: resultStore.getPotentialByTechnologyKw.pvRoof / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.PV_ROOF },
      },
    ],
  },
  {
    name: 'Bestand',
    type: 'bar',
    barWidth: 25,
    xAxisIndex: 0,
    yAxisIndex: 0,
    data: [
      {
        value: resultStore.getGenerationByTechnologyKw.hydroPower / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.HYDRO },
      },
      {
        value: resultStore.getGenerationByTechnologyKw.biomass / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.BIOMASS },
      },
      {
        value: resultStore.getGenerationByTechnologyKw.wind / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.WIND },
      },
      {
        value: resultStore.getGenerationByTechnologyKw.pvFree / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.PV_FREE },
      },
      {
        value: resultStore.getGenerationByTechnologyKw.pvRoof / 1000,
        itemStyle: { color: RENEWABLE_GENERATION.PV_ROOF },
      },
    ],
  },
]);

const labels = [
  'Wasserkraft*',
  'Biomasse*',
  'Wind',
  'PV-Freifläche',
  'PV-Aufdach',
];

function getAllowTooltips() {
  return (param) => {
    const isStock = param.seriesName === 'Bestand';
    const isPotential = param.seriesName === 'Potenzial';
    const isExcludedAxis = ['Wasserkraft*', 'Biomasse*'].includes(
      param.axisValue,
    );

    return isStock || (isPotential && !isExcludedAxis);
  };
}
</script>

<style scoped>
.project-overview {
  min-height: calc(100vh - 290px);
}
</style>
