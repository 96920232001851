<template>
  <div class="flex flex-col gap-5">
    <div
      class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-5"
    >
      <div class="flex items-center gap-2">
        <h5 class="text-title-color1">
          Anschlussbereiche der relevanten Anschlussleistung* für
          PV-Potenzialflächen
        </h5>
        <ToolTip :tooltip-text="tooltipBatteryCapacity" />
      </div>
      <div
        class="flex flex-col gap-5 p-5 standard-elevation-0-dark rounded-[4px]"
      >
        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">
              Mittelspannung – Netzverknüpfungspunkt (mit freier Netzkapazität)
            </div>
            <ToolTip :tooltip-text="tooltipMediumVoltageConnection" />
          </div>
          <div class="grid grid-cols-2 gap-2.5 w-[490px]">
            <InputEl
              :disabled="true"
              label="Anschlussleistung von"
              input-type="float"
              placeholder="0"
              class="w-full"
              suffix="kW"
            />
            <InputEl
              v-model="maxLoadGridConnection"
              label="bis"
              input-type="float"
              placeholder="Bitte eingeben"
              class="w-full"
              suffix="kW"
              :disabled="readOnly"
              :rules="
                getValidationRules(
                  formRules.max_load_for_grid_connection_point_kw,
                )
              "
            />
          </div>
        </div>
        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">
              Mittelspannung - Umspannwerk Netzbetreiber (mit freier
              Netzkapazität)
            </div>
            <ToolTip :tooltip-text="tooltipSubstationNoCapacity" />
          </div>
          <div class="grid grid-cols-2 gap-2.5 w-[490px]">
            <InputEl
              v-model="maxLoadGridConnection"
              :disabled="true"
              label="Anschlussleistung von"
              input-type="float"
              placeholder="Bitte eingeben"
              suffix="kW"
              class="w-full"
            />
            <InputEl
              v-model="maxLoadSubstation"
              label="bis"
              input-type="float"
              placeholder="Bitte eingeben"
              suffix="kW"
              class="w-full"
              :disabled="readOnly"
              :rules="getValidationRules(formRules.max_load_for_substation_kw)"
            />
          </div>
        </div>

        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">Hochspannung</div>
            <ToolTip :tooltip-text="tooltipHighVoltageConnection" />
          </div>
          <div class="grid grid-cols-2 gap-2.5 w-[490px]">
            <InputEl
              v-model="maxLoadSubstation"
              :disabled="true"
              label="Anschlussleistung von"
              input-type="float"
              placeholder="Bitte eingeben"
              suffix="kW"
              class="w-full"
            />
            <InputEl
              v-model="maxLoadHighVoltageConnection"
              label="bis"
              input-type="float"
              placeholder="Bitte eingeben"
              suffix="kW"
              class="w-full"
              :disabled="readOnly"
              :rules="
                getValidationRules(formRules.max_load_for_high_voltage_kw)
              "
            />
          </div>
        </div>

        <div class="flex flex-col gap-2.5">
          <div class="flex justify-between items-center gap-5">
            <div class="subtitle-2 text-neutral">
              Radius für Netzanschlusssuche NVP/Umspannwerk
            </div>
            <ToolTip :tooltip-text="tooltipBatteryCapacity" />
          </div>
          <div class="grid grid-cols-2 gap-5">
            <InputEl
              v-model="connectionRadius"
              input-type="integer"
              placeholder="Bitte eingeben"
              suffix="m"
              class="w-[240px]"
              :disabled="readOnly"
              :rules="getValidationRules(formRules.connection_radius_m)"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-5"
    >
      <h5 class="text-title-color1">Dimensionierung Energiespeicher</h5>

      <div class="flex items-center gap-3">
        <InputEl
          v-model="batteryProportion"
          input-type="percent"
          placeholder="Bitte eingeben"
          class="w-[240px]"
          suffix="%"
          :disabled="readOnly"
          :rules="getValidationRules(formRules.battery_capacity_proportion)"
        />
        <span class="body-2 pt-0.5"
          >Auslegungsleistung (anteilig an installierter PV-Leistung)</span
        >
        <ToolTip :tooltip-text="tooltipBatteryCapacity" class="ml-auto" />
      </div>
    </div>

    <div
      class="w-full standard-elevation-0-dark rounded-[2px] p-5 flex flex-col gap-5"
    >
      <h5 class="text-title-color1">Projektcluster</h5>
      <div class="flex justify-between items-center">
        <CheckboxEl v-model="clusterApplyAnalysis" :disabled="readOnly">
          <div class="subtitle-2 text-neutral pt-[3px]">
            Projektcluster berücksichtigen (eigenes USW an HS-Leitung)
          </div>
        </CheckboxEl>

        <ToolTip :tooltip-text="tooltipBatteryCapacity" />
      </div>
      <template v-if="clusterApplyAnalysis">
        <InputEl
          v-model="clusterTransformerMax"
          label="Definiere max. Leistung Umspannwerk"
          input-type="float"
          suffix="MW"
          :disabled="readOnly"
          class="w-[240px]"
        />

        <div
          class="flex flex-col gap-5 standard-elevation-0-dark rounded-[4px] p-5"
        >
          <div class="subtitle-2 text-neutral">
            Kriterien zur Zusammenlegung relevanter Potenzialflächen
            (Einzelprojekten)
          </div>
          <div
            class="flex flex-col gap-2.5 standard-elevation-0-dark rounded-[1px] p-5"
          >
            <div class="subtitle-2 text-color1">Einzelprojektgröße</div>
            <div class="grid grid-cols-2 gap-2.5 w-[490px]">
              <InputEl
                v-model="clusterConnectedLoadMin"
                label="Anschlussleistung von"
                input-type="float"
                placeholder="10"
                suffix="MW"
                class="w-full"
                :disabled="readOnly"
              />
              <InputEl
                v-model="clusterConnectedLoadMax"
                label="bis"
                input-type="float"
                placeholder="40"
                suffix="MW"
                class="w-full"
                :disabled="readOnly"
              />
            </div>
            <CheckboxEl
              v-model="clusterConsiderBattery"
              class="flex-row-reverse"
              :disabled="readOnly"
            >
              <div class="flex items-center">
                <IconWrapper
                  fill="text-title-neutral"
                  :size="32"
                  icon="battery_charging_full"
                />
                <div class="body-2 text-neutral pt-[2px]">
                  mit Berücksichtigung Batteriespeicher
                </div>
              </div>
            </CheckboxEl>
          </div>

          <div
            class="flex flex-col gap-3 standard-elevation-0-dark rounded-[1px] p-5"
          >
            <div class="flex justify-between items-center gap-5">
              <div class="subtitle-2 text-color1">
                Nur Einzelprojekte, für die keine NVP/USW mit ausreichend freier
                Kapazität gefunden wurden:
              </div>
              <ToolTip :tooltip-text="tooltipSubstationNoCapacity" />
            </div>
            <div class="flex gap-7">
              <CheckboxEl
                v-model="clusterConsiderOnlyNoCapacity"
                class="flex-row-reverse"
                :disabled="readOnly"
                value="GRID"
              >
                <div class="flex items-center gap-1.5">
                  <IconWrapper
                    :size="32"
                    fill="text-title-neutral"
                    icon="link"
                  />
                  <div class="body-2 text-neutral pt-[2px]">
                    Netzverknüpfungspunkt
                  </div>
                </div>
              </CheckboxEl>
              <CheckboxEl
                v-model="clusterConsiderOnlyNoCapacity"
                class="flex-row-reverse"
                :disabled="readOnly"
                value="TRANSFORMER"
              >
                <div class="flex items-center gap-1.5">
                  <IconWrapper
                    fill="text-title-neutral"
                    :icon="IconTrafo"
                    :size="32"
                    class="mb-[3px]"
                  />
                  <div class="body-2 text-neutral pt-[2px]">
                    Umspannwerk Netzbetreiber
                  </div>
                </div>
              </CheckboxEl>
            </div>
          </div>

          <div
            class="flex items-end gap-2.5 standard-elevation-0-dark rounded-[1px] px-5 py-4"
          >
            <IconWrapper
              :size="28"
              fill="text-title-neutral"
              icon="schema"
              class="mb-1.5"
            />
            <InputEl
              v-model="clusterNumberProjectMax"
              label="Max. Anzahl an Einzelprojekten"
              input-type="integer"
              placeholder="4"
              class="w-[240px]"
              :disabled="readOnly"
            />
          </div>

          <div class="grid grid-cols-2 gap-3">
            <div
              class="flex items-end standard-elevation-0-dark gap-2.5 rounded-[1px] px-5 py-4"
            >
              <IconWrapper
                :size="28"
                fill="text-title-neutral"
                icon="shape_line"
                class="mb-2"
              />
              <InputEl
                v-model="clusterDistanceGridMax"
                label="Max. Distanz Einzelprojekte zur nächsten HS-Leitung (Suchräume)"
                input-type="integer"
                class="w-full"
                suffix="m"
                :disabled="readOnly"
              />
            </div>
            <div
              class="flex items-end standard-elevation-0-dark gap-2.5 rounded-[1px] px-5 py-4"
            >
              <IconWrapper
                :size="32"
                fill="text-title-neutral"
                icon="scatter_plot"
                class="mb-1.5"
              />
              <InputEl
                v-model="clusterDistanceProjectsMax"
                label="Räumliche Nähe Einzelprojekte zueinander"
                input-type="integer"
                placeholder="2.000"
                suffix="m"
                class="w-full"
                :disabled="readOnly"
              />
              <ToolTip
                :tooltip-text="tooltipSubstationNoCapacity"
                class="mb-2"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <CheckboxEl
            v-model="clusterConsiderExternalProjects"
            class="flex-row-reverse"
            :disabled="readOnly"
          >
            <div class="body-2 text-neutral pt-[2px]">
              Drittprojekte berücksichtigen
            </div>
          </CheckboxEl>

          <ToolTip :tooltip-text="tooltipSubstationNoCapacity" />
        </div>
      </template>
    </div>

    <div
      class="w-full standard-elevation-0-dark rounded-[6px] p-5 flex flex-col gap-5"
    >
      <h5 class="text-title-color1">Route Netzanschluss</h5>
      <VRadioGroup v-model="gridConnectionChoice" class="max-w-fit">
        <VRadio
          v-for="(choice, index) in gridConnectionChoices"
          :key="choice.value"
          :value="choice.value"
          :disabled="readOnly"
        >
          <template #label>
            <div class="body-2 pt-0.5 my-2">
              {{ choice.display_name }}
            </div>
            <ToolTip
              v-if="index === 1"
              :tooltip-text="tooltipBatteryConsideration"
              class="ml-2"
            />
          </template>
        </VRadio>
      </VRadioGroup>
    </div>
  </div>
</template>

<script setup>
import IconTrafo from '@/assets/icons/custom/energy-grid/IconTrafo.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { getValidationRules } from '@/utils/rest-utils';
import { toRefs, useVModel } from '@vueuse/core';

const props = defineProps({
  formRules: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
  metaData: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:modelValue']);

const {
  routing_option: { choices: gridConnectionChoices },
} = props.metaData;

const {
  max_load_for_grid_connection_point_kw: maxLoadGridConnection,
  max_load_for_substation_kw: maxLoadSubstation,
  max_load_for_high_voltage_kw: maxLoadHighVoltageConnection,
  battery_capacity_proportion: batteryProportion,
  connection_radius_m: connectionRadius,
  routing_option: gridConnectionChoice,
  cluster_apply_analysis: clusterApplyAnalysis,
  cluster_transformer_load_max_kw: clusterTransformerMax,
  cluster_connected_load_min_kw: clusterConnectedLoadMin,
  cluster_connected_load_max_kw: clusterConnectedLoadMax,
  cluster_consider_battery: clusterConsiderBattery,
  cluster_consider_only_no_capacity: clusterConsiderOnlyNoCapacity,
  cluster_number_project_max_m: clusterNumberProjectMax,
  cluster_distance_grid_max_m: clusterDistanceGridMax,
  cluster_distance_projects_max: clusterDistanceProjectsMax,
  cluster_consider_external_projects: clusterConsiderExternalProjects,
} = toRefs(useVModel(props, 'modelValue', emit));

// TODO: Validation dass grid nicht größer als substation

// tooltip texts
const tooltipBatteryCapacity =
  'Die tatsächliche Speicherleistung ist ein Vielfaches des hinterlegten Speichermoduls (siehe allgemeine Berechnungsparameter) und kann dementsprechend minimal von der prozentualen Auslegungsleistung abweichen.';

const tooltipMediumVoltageConnection = `Der Anschluss erfolgt an einen Netzverknüpfungspunkt der Mittelspannung mit freier Netzkapazität (der Bau eines Umspannwerkes wird kostenseitig nicht berücksichtigt).
  Ist kein Netzverknüpfungspunkt verfügbar, erfolgt der Anschluss an den Netzverknüpfungspunkt, der die höchste verfügbare Netzkapazität aufweist(auf das Delta wird in der Auswertung hingewiesen).`;

const tooltipHighVoltageConnection =
  'Der Anschluss erfolgt direkt an die Hochspannungstrasse (Freileitung), so dass der Bau eines Umspannwerkes im Kontext Netzanschluss kostenseitig mit berücksichtigt wird.';

const tooltipSubstationNoCapacity =
  'Der Anschluss erfolgt an ein Umspannwerk des Netzbetreibers in der Mittelspannung ohne Berücksichtigung freier Netzkapazität (der Bau eines Umspannwerkes wird kostenseitig nicht berücksichtigt).';

const tooltipBatteryConsideration = `Bei Berücksichtigung von Batteriespeichern wird über eine Routenplanung der nächstgelegene Netzanschlusspunkt ermittelt, der über einen minimale freie Kapazität verfügt (einzustellen in den allgemeinen Berechnungsparametern).

Die verbleibende Kapazitätslücke wird mit einem Batteriespeicher gedeckt. Dieser Anschluss-Case wird hinsichtlich der gesamten Netzanschlusskosten mit dem Anschluss-Case verglichen, bei dem ein Netzanschluss an ausreichend freie Netzkapazitäten erfolgt.

Es wird der Anschluss-Case gewählt, bei dem die gesamten Netzanschlusskosten geringer ausfallen.`;
</script>
