<template>
  <div class="absolute flex left-5 z-[100] h-10">
    <div class="w-[360px] flex gap-[5px]">
      <div class="standard-elevation-2 flex rounded-md gap-[5px]">
        <ToHome
          class="z-10"
          :class="{ 'rounded-r-none': !showScenarioSelector }"
        />

        <IconWrapper
          v-show="!showScenarioSelector"
          icon="pin_drop"
          fill="text-color1"
          :class="
            showScenarioSelector
              ? '-ml-[45px] rounded-md'
              : '-ml-[5px] rounded-r-md'
          "
          class="transition-all bg-color2 h-10 px-2 cursor-pointer"
          icon-type="round"
          @click="toggleScenarioSelector"
        />
      </div>

      <ButtonEl
        data-test="main-menu-toggle"
        icon="segment"
        :variant="toggleVariant('mainMenu')"
        :color="toggleColor('mainMenu')"
        icon-type="round"
        class="rounded-[8px] h-10 standard-elevation-2"
        @click="toggleMainMenu"
      />

      <SearchBar :map-store />
    </div>
  </div>
</template>

<script setup>
import ToHome from '@/apps/features/map/control-options/components/ToHome.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import SearchBar from '@/apps/features/map/control-options/search/SearchBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { toRefs } from 'vue';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const { showMainMenu, showScenarioSelector } = toRefs(props.mapStore);
const { toggleMainMenu, toggleScenarioSelector } = props.mapStore;

if (showMainMenu.value) {
  toggleMainMenu();
}

if (!showScenarioSelector.value) {
  toggleScenarioSelector();
}

function toggleVariant(type) {
  if (type === 'mainMenu') {
    return showMainMenu.value ? 'primary' : 'primary';
  }
}

function toggleColor(type) {
  if (type === 'mainMenu') {
    return showMainMenu.value ? 'color1' : 'color2';
  }
}
</script>
