export class PotentialAnalysisDto {
  static BASE_URL = '/api/usecase-2/analysis/';

  id = null;
  title = '';
  description = '';
  municipalities = [];
  min_area_m2 = 20;
  max_count_parcels = 100;
  orientation_value_greater_equal_than = 135;
  orientation_value_smaller_equal_than = 225;
  slope_degree_value_greater_equal_than = 0;
  slope_degree_value_smaller_equal_than = 45;
  max_load_for_grid_connection_point_kw = 15000;
  max_load_for_substation_kw = 25000;
  max_load_for_high_voltage_kw = 100000;
  connection_radius_m = 10000;
  battery_capacity_proportion = 0.4;
  routing_option = 0;
  disadvantage_area_list = [0, 1, 2];
  agricultural_plots_list = [0, 1];
  specific_plant_size_kw_m2 = 1200 / 10000;
  dc_ac_ratio = 0.8;
  inverter_efficiency = 0.95;
  performance_ratio = 0.95;
  mounting_slope_degree = 40;
  cluster_apply_analysis = false;
  cluster_transformer_load_max_kw = 60000;
  cluster_connected_load_min_kw = 10000;
  cluster_connected_load_max_kw = 40000;
  cluster_consider_battery = true;
  cluster_consider_only_no_capacity = [];
  cluster_number_project_max_m = 4;
  cluster_distance_grid_max_m = 2000;
  cluster_distance_projects_max = 2000;
  cluster_consider_external_projects = true;
  configrestrictions_set = [];
}
