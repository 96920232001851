// An object representing the hierarchy of different map layers.
// Each key corresponds to a layer type and the value is an array to hold layer IDs.
const layerHierarchy = {
  heat: [],
  poly: [],
  line: [],
  fill: [],
  point: [],
  symbol: [],
};

/**
 * Adjusts the order of map layers according to a predefined hierarchy.
 * Ensures that specific layers are moved below drawing layers and labels are placed on top.
 *
 * @param {object} map - The map object on which to reorder layers.
 */
export function setLayerHierarchy(map) {
  // Example of explicitly placing the satellite layer
  if (map.getLayer('satellite')) {
    // map.moveLayer('satellite', 'gl-draw-point-heat-source.cold'); // Ensure it's below your drawing layers
  }

  // Move other layers as per the predefined hierarchy
  for (let i = 0; i < Object.values(layerHierarchy).length; i += 1) {
    Object.values(layerHierarchy)[i].forEach((layer) => {
      if (map.getLayer(layer)) {
        map.moveLayer(layer);
      }
    });
  }

  // Ensuring labels are on top
  map.getStyle().layers.forEach((e) => {
    if (e.id.split('-').includes('label') && map.getLayer(e.id)) {
      map.moveLayer(e.id);
    }
  });
}

/**
 * Constructs a tile server URL based on a source layer.
 *
 * @param {string} sourceLayer - The source layer name.
 * @returns {string} - The complete tile server URL.
 */
export function getTileServerUrl(sourceLayer) {
  return `${window.TILESERVER_URL}/ts/tiles/${sourceLayer}/{z}/{x}/{y}.pbf`;
}

/**
 * Appends a selected analysis ID as a suffix to the layer ID.
 *
 * @param {object} mapStore - A reference to the map's state or store.
 * @param {object} layer - The layer configuration object.
 * @param {string} selectedAnalysisId - The ID of the selected analysis.
 * @returns {string} - The updated layer ID with the analysis suffix.
 */
function addAnalysisSuffix(mapStore, layer, selectedAnalysisId) {
  return `${selectedAnalysisId}_${layer.layerId}`;
}

/**
 * Adds icon layers to the map, loading and adding images for each icon.
 *
 * @param {object} config - The configuration object containing icon details.
 * @param {object} map - The map object to which icons will be added.
 */
function addIconsLayer(config, map) {
  if (config.icons) {
    config.icons.forEach((icon) => {
      map.loadImage(icon.path, (error, image) => {
        if (error) throw error;
        if (!map.hasImage(icon.name)) {
          map.addImage(icon.name, image);
        }
      });
    });
  }
}

/**
 * Adds a polygon layer to the map, based on the given configuration and styling.
 *
 * @param {object} config - The configuration object containing polygon layer details.
 * @param {object} map - The map object to which the polygon layer will be added.
 * @param {object} styling - The styling configuration for the polygon layer.
 */
function addPolygonLayer(config, map, styling) {
  if (config.type === 'polygon') {
    if (config.subType === 'fillExtrusion') {
      layerHierarchy.fill.push(config.layerId);
      map.U.addFillExtrusionLayer(config.layerId, config.id, {
        visibility: config.visibility,
        'fill-extrusion-color': styling.fillExtrusionColor,
        'fill-extrusion-height': styling.fillExtrusionHeight,
        'fill-extrusion-opacity': styling.fillExtrusionOpacity,
        ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
        ...(config.filter && { filter: config.filter }),
      });
    } else {
      layerHierarchy.poly.push(config.layerId);
      map.U.addFillLayer(config.layerId, config.id, {
        'fill-color': styling.fillColor,
        visibility: config.visibility,
        'fill-opacity': styling.fillOpacity,
        ...(styling.fillOutlineColor && {
          'fill-outline-color': styling.fillOutlineColor,
        }),
        ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
        ...(config.filter && { filter: config.filter }),
      });
    }
  }
}

/**
 * Adds a point layer to the map using circle styling.
 *
 * @param {object} config - The configuration object for the point layer.
 * @param {object} map - The map object to which the point layer will be added.
 * @param {object} styling - The styling configuration for the point layer.
 */
function addPointLayer(config, map, styling) {
  if (config.type === 'point') {
    layerHierarchy.point.push(config.layerId);
    map.U.addCircleLayer(config.layerId, config.id, {
      paint: {
        'circle-color': styling.circleColor,
        'circle-stroke-width': styling.circleStrokeWidth,
        'circle-stroke-color': styling.circleStrokeColor,
        'circle-radius': styling.circleRadius,
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
      visibility: config.visibility,
    });
  }
}

/**
 * Adds a line layer to the map with the specified styling.
 *
 * @param {object} config - The configuration object for the line layer.
 * @param {object} map - The map object to which the line layer will be added.
 * @param {object} styling - The styling configuration for the line layer.
 */
function addLineLayer(config, map, styling) {
  if (config.type === 'line') {
    layerHierarchy.line.push(config.layerId);
    map.U.addLineLayer(config.layerId, config.id, {
      paint: {
        'line-color': styling.lineColor,
        'line-width': styling.lineWidth,
      },
      visibility: config.visibility,
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
    });
  }
}

/**
 * Adds a symbol layer to the map based on the given configuration and styling.
 *
 * @param {object} config - The configuration object for the symbol layer.
 * @param {object} map - The map object to which the symbol layer will be added.
 * @param {object} styling - The layout and styling configuration for the symbol layer.
 */
function addSymbolLayer(config, map, styling) {
  if (config.type === 'symbol') {
    layerHierarchy.symbol.push(config.layerId);
    map.U.addSymbolLayer(config.layerId, config.id, {
      layout: styling.layout,
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
    });
  }
}

/**
 * Adds a heatmap layer to the map based on the configuration and heatmap styling.
 *
 * @param {object} config - The configuration object for the heatmap layer.
 * @param {object} map - The map object to which the heatmap layer will be added.
 * @param {object} styling - The styling configuration for the heatmap layer.
 */
function addHeatmapLayer(config, map, styling) {
  if (config.type === 'heatmap') {
    layerHierarchy.heat.push(config.layerId);
    map.U.addHeatmapLayer(config.layerId, config.id, {
      visibility: config.visibility,
      paint: {
        'heatmap-weight': styling.weight,
        'heatmap-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          1,
          7,
          10,
          12,
          15,
        ],
        'heatmap-color': styling.color,
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
    });
  }
}

/**
 * Creates and adds multiple layers to the map based on the provided configurations.
 * The function handles adding vector and GeoJSON sources, applies layer styling,
 * and then adds polygon, point, line, symbol, and heatmap layers.
 *
 * @param {object} map - The map object where the layers will be created.
 * @param {object} layers - The collection of layer configurations.
 * @param {function} setGeoJsonData - A function to set the GeoJSON data.
 * @param {object} mapStore - A reference to the map's state/store.
 * @param {number} getYear - A function that returns the current year for dynamic styling.
 * @param {string} selectedAnalysisId - The ID of the selected analysis.
 */
export function createLayers(
  map,
  layers,
  setGeoJsonData,
  mapStore,
  getYear,
  selectedAnalysisId,
) {
  Object.entries(layers).forEach((layer) => {
    const item = layer[1];
    const config = item.layerConfig;
    let styling;
    if (typeof item.style.default === 'function') {
      styling = item.style.default(getYear);
    } else {
      styling = item.style.default;
    }
    if (config.dependsOnScenario !== false) {
      config.sourceLayer = addAnalysisSuffix(
        mapStore,
        config,
        selectedAnalysisId,
      );
    }
    if (!map.getSource(config.id) && !config.geoJSONSource) {
      map.U.addVector(config.id, getTileServerUrl(config.sourceLayer));
    }
    if (!map.getSource(config.id) && config.geoJSONSource) {
      map.U.addGeoJSON(config.id);
    }
    addIconsLayer(config, map);
    addPolygonLayer(config, map, styling);
    addPointLayer(config, map, styling);
    addLineLayer(config, map, styling);
    addSymbolLayer(config, map, styling);
    addHeatmapLayer(config, map, styling);
  });
  setLayerHierarchy(map);
}
