<template>
  <div class="circle-container">
    <svg :width="size" :height="size" viewBox="-5 -5 110 110">
      <!-- Full Circle or Partial Circle -->
      <path
        v-if="props.maxValidAngle"
        :d="dynamicCirclePath"
        :fill="hideCircle ? 'transparent' : '#F3EFEC'"
        stroke="none"
      />
      <circle
        v-else
        cx="50"
        cy="50"
        r="45"
        :fill="hideCircle ? 'transparent' : '#F3EFEC'"
        stroke="none"
      />

      <!-- Active "Piece of Cake" (no stroke to center) -->
      <path :d="slicePath" fill="#C3BCB6" stroke="none" />

      <!-- Lines from border to center -->
      <line
        :x1="startCoords.x"
        :y1="startCoords.y"
        x2="50"
        y2="50"
        stroke="#009"
        stroke-width="1"
      />
      <line
        :x1="endCoords.x"
        :y1="endCoords.y"
        x2="50"
        y2="50"
        stroke="#3CAAF0"
        stroke-width="1"
      />

      <!-- Arc Border Only at the Edge -->
      <!-- <path :d="arcPath" fill="none" stroke="#009" stroke-width="1" /> -->

      <!-- Dots on the border with white borders -->
      <!-- <circle
        :cx="startCoords.x"
        :cy="startCoords.y"
        r="3"
        fill="#009"
        stroke="white"
        stroke-width="1"
      />
      <circle
        :cx="endCoords.x"
        :cy="endCoords.y"
        r="3"
        fill="#009"
        stroke="white"
        stroke-width="1"
      /> -->

      <!-- Center Blue Dot -->
      <circle cx="50" cy="50" r="1.5" fill="#009" />

      <!-- Orientation Labels (Always on Top) -->
      <template v-if="showOrientationLabels">
        <text
          v-for="(label, index) in orientationLabels"
          :key="label"
          :x="50 + 50 * Math.sin((index * 90 - startAt) * (Math.PI / 180))"
          :y="50 - 50 * Math.cos((index * 90 - startAt) * (Math.PI / 180))"
          text-anchor="middle"
          font-size="5"
          fill="#333"
          class="orientation-label"
          dy="0.3em"
        >
          {{ label }}
        </text>
      </template>
    </svg>
  </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [0, 90], // Starting and ending angles
  },
  size: {
    type: Number,
    default: 250,
  },
  showOrientationLabels: {
    type: Boolean,
    default: true,
  },
  startAt: {
    type: Number,
    default: 0,
  },
  clockwise: {
    type: Boolean,
    default: true,
  },
  hideCircle: {
    type: Boolean,
    default: false,
  },
  maxValidAngle: {
    type: Number,
    default: null,
  },
  orientationLabels: {
    type: Array,
    default: () => ['N', 'O', 'S', 'W'],
  },
});

const emit = defineEmits(['update:modelValue']);

const angles = useVModel(props, 'modelValue', emit);

// Helper functions
const toRadians = (angleInDegrees) => {
  const adjustedAngle = props.clockwise
    ? angleInDegrees - 90 + props.startAt
    : 90 - angleInDegrees - props.startAt;
  return adjustedAngle * (Math.PI / 180);
};

const polarToCartesian = (angle, radius) => {
  const x = 50 + radius * Math.cos(toRadians(angle));
  const y = 50 + radius * Math.sin(toRadians(angle));
  return { x, y };
};

// Validation function for angles
const isValidAngles = (angles) => {
  const [start, end] = angles;
  const maxAngle = props.maxValidAngle ?? 360;
  return start >= 0 && start <= maxAngle && end >= 0 && end <= maxAngle;
};

// Functions to calculate the paths, adjusted for full circle
const calculatePath = (startAngle, endAngle) => {
  const radius = 45;
  const maxAngle = props.maxValidAngle ?? 360;
  const clampedStartAngle = Math.min(startAngle, maxAngle);
  const clampedEndAngle = Math.min(endAngle, maxAngle);
  const start = polarToCartesian(clampedStartAngle, radius);
  const end = polarToCartesian(clampedEndAngle, radius);

  // Normalize angles to handle the 0/360 crossing
  const normalizedEnd =
    clampedEndAngle < clampedStartAngle
      ? clampedEndAngle + 360
      : clampedEndAngle;
  const angleDiff = normalizedEnd - clampedStartAngle;

  const largeArcFlag = angleDiff > 180 ? '1' : '0';
  const sweepFlag = props.clockwise ? '1' : '0';

  return `
    M 50 50
    L ${start.x} ${start.y}
    A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${end.x} ${end.y}
    Z
  `;
};

const calculateArcPath = (startAngle, endAngle) => {
  const radius = 45;
  const maxAngle = props.maxValidAngle ?? 360;
  const clampedStartAngle = Math.min(startAngle, maxAngle);
  const clampedEndAngle = Math.min(endAngle, maxAngle);
  const start = polarToCartesian(clampedStartAngle, radius);
  const end = polarToCartesian(clampedEndAngle, radius);

  // Normalize angles to handle the 0/360 crossing
  const normalizedEnd =
    clampedEndAngle < clampedStartAngle
      ? clampedEndAngle + 360
      : clampedEndAngle;
  const angleDiff = normalizedEnd - clampedStartAngle;

  const largeArcFlag = angleDiff > 180 ? '1' : '0';
  const sweepFlag = props.clockwise ? '1' : '0';

  return `
    M ${start.x} ${start.y}
    A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${end.x} ${end.y}
  `;
};

// Computed values for coordinates
const startCoords = computed(() => {
  if (!isValidAngles(angles.value)) return { x: 50, y: 50 };
  const angle = angles.value[0];
  return polarToCartesian(angle, 45);
});

const endCoords = computed(() => {
  if (!isValidAngles(angles.value)) return { x: 50, y: 50 };
  const angle = angles.value[1];
  return polarToCartesian(angle, 45);
});

const slicePath = computed(() => {
  if (!isValidAngles(angles.value)) return '';
  const [start, end] = angles.value;
  return calculatePath(start, end);
});

const arcPath = computed(() => {
  if (!isValidAngles(angles.value)) return '';
  const [start, end] = angles.value;
  return calculateArcPath(start, end);
});

// Function to calculate a dynamic circle path based on maxValidAngle
const calculateDynamicCirclePath = (angle) => {
  const radius = 45;
  const start = polarToCartesian(0, radius);
  const end = polarToCartesian(angle, radius);
  const largeArcFlag = angle > 180 ? '1' : '0';
  const sweepFlag = props.clockwise ? '1' : '0';

  return `
    M 50 50
    L ${start.x} ${start.y}
    A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${end.x} ${end.y}
    Z
  `;
};

// Computed value for dynamic circle path
const dynamicCirclePath = computed(() => {
  return calculateDynamicCirclePath(props.maxValidAngle);
});
</script>

<style scoped lang="scss">
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orientation-label {
  fill: #333;
}
</style>
