<template>
  <div
    v-if="panelData"
    class="bg-white overflow-visible standard-elevation-2 animate-none rounded-[8px]"
  >
    <div v-show="show">
      <div
        class="relative overflow-y-auto flex flex-col gap-2.5 items-start hide-scrollbar p-2.5 rounded-[8px]"
        style="width: 360px"
        :style="{ maxHeight: containerHeight - 160 + 'px' }"
      >
        <InfoPanelHeader
          :panel-name="panelData.name"
          :updated-info="panelData.updatedInfo"
          @close-click="show = false"
        />
        <InfoPanelSubtitles
          :map-store
          :subtitles="panelData.subtitles"
          :feature="feature"
        />
        <InfoPanelContent
          :feature="feature"
          :panel-data="panelData"
          @close-info-panel="show = false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import InfoPanelContent from '@/apps/features/map/info-panel/InfoPanelContent.vue';
import InfoPanelHeader from '@/apps/features/map/info-panel/InfoPanelHeader.vue';
import InfoPanelSubtitles from '@/apps/features/map/info-panel/InfoPanelSubtitles.vue';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';

const props = defineProps({
  feature: Object,
  featureId: [Number, String],
  mapStore: {
    type: Object,
    required: true,
  },
  panelObjects: {
    type: Array,
    required: true,
  },
});

const show = ref(false);
const featureData = ref(null);

const { year: scenarioYear, refreshFeature } = storeToRefs(props.mapStore);

const { height: containerHeight } = useWindowSize();

const panelData = ref(null);
const panel = ref(null);

const panelsByLayerId = {};
for (const panelObject of props.panelObjects) {
  for (const layerId of panelObject.layerIds) {
    panelsByLayerId[layerId] = panelObject;
  }
}

watch(
  [() => props.feature, refreshFeature],
  async ([newFeature]) => {
    // new feature was clicked or feature refresh was triggered by feature patch
    if (!newFeature) {
      show.value = false;
      return;
    }
    panel.value = panelsByLayerId[newFeature.layer.id];
    if (!panel.value) {
      console.log('no panel found for layer with id ', newFeature.layer.id);
      return;
    }
    featureData.value = await panel.value.requestFeatureData(
      newFeature,
      props.mapStore,
    );
  },
  { immediate: true },
);

watch(
  [featureData, scenarioYear],
  async (
    [newFeatureData, newScenarioYear],
    [oldFeatureData, oldScenarioYear],
  ) => {
    // feature data or scenario year changed, thus recalculate panel data
    if (newFeatureData === null) return;
    if (newFeatureData === oldFeatureData && !show.value) {
      return;
    }
    panelData.value = panel.value.calculatePanelData(
      newFeatureData,
      newScenarioYear,
      props.mapStore,
    );
    panelData.value.id = props.featureId;
    show.value = true;
  },
);
</script>

<style lang="scss">
.info-panel-more-options {
  @apply px-2 py-[5px] hover:bg-infra-background w-full;
}
</style>
