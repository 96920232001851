export default function onLeftClick(
  map,
  e,
  methods,
  click,
  getLayersToClick,
  mapStore,
) {
  click.resetFeatureState();

  click.iconLayers.forEach((el) => {
    map.U.removeSource(el);
  });
  const features = map.queryRenderedFeatures(e.point, {
    layers: getLayersToClick,
  });

  const feature = features[0];
  methods.featureClicked(feature);
  console.log(feature);
  if (feature == null) {
    mapStore.setShowInfoPanel(false);
  } else {
    const layerConfig = mapStore.layerConfigs[feature.layer.id];
    if (layerConfig.onClick) layerConfig.onClick(click, feature);

    const idKey = layerConfig.layerConfig.key_name;
    methods.featureIdClicked(feature.properties[idKey]);

    click.setFeatureState([feature], true);
    mapStore.setShowInfoPanel(true);
  }
}
