<template>
  <div
    class="flex justify-between w-full panelItem py-1 border-b last:border-b-0 border-line-disabled-neutral singleItemcustom"
  >
    <div class="flex items-center relative body-3" :style="{ width: '60%' }">
      {{ item.label }}
    </div>

    <div
      class="flex items-center"
      :style="{ width: '38%' }"
      :class="
        item.type !== 'boolean'
          ? 'overflow-hidden hover:overflow-visible hover:z-10 justify-start'
          : 'justify-end'
      "
    >
      <div class="flex w-full caption-1">
        <span class="whitespace-nowrap">{{ item.unit }}</span>
        <div class="flex justify-end w-fit ml-auto white-space-normal">
          <div v-if="formattedValue.length <= 15" class="less-15">
            {{ formattedValue }}
          </div>
          <div v-else class="more-15">
            <VTooltip :text="item.value.toLocaleString('de-DE')">
              <template #activator="{ props }">
                <div v-bind="props">
                  {{ truncatedValue }}
                </div>
              </template>
            </VTooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

// Computed property for formatted value
const formattedValue = computed(() => {
  return props.item.value ? props.item.value : '';
});

// Computed property to return truncated string if too long
const truncatedValue = computed(() => {
  if (!props.item.value) return '';
  const formatted = formattedValue.value;
  return formatted.length > 15 ? `${formatted.slice(0, 14)}...` : formatted;
});
</script>

<style lang="scss">
.panelItem {
  .left-outside-custom {
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .right-outside-custom {
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
