import {
  SUN_YELLOW_40,
  TAROCCO_40,
  DEEP_BLUE_LIGHT,
  NEUTRAL_GREY_60,
  AQUAMARIN_70,
  AZUR_BLUE_30,
  IMPULSE_ORANGE_MEDIUM,
  BLACK,
  AQUAMARIN_40,
} from '@/configs/color-constants';
export const LAYER_KEY__HIGH_VOLTAGE_GRID = 'high_voltage_grid';
export const LAYER_KEY__GRID_STATIONS = 'grid_stations';
export const LAYER_KEY__SEARCH_AREA_STATIONS =
  'search_area_new_transformer_stations';
export const LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS =
  'heat_density_street_segments';

export default {
  [LAYER_KEY__SEARCH_AREA_STATIONS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__SEARCH_AREA_STATIONS,
      sourceLayer: LAYER_KEY__SEARCH_AREA_STATIONS,
      layerId: LAYER_KEY__SEARCH_AREA_STATIONS,
      visibility: 'none',
      dependsOnScenario: false,
    },
    style: {
      default: {
        fillColor: AQUAMARIN_40,
        fillOpacity: 0.7,
        fillOutlineColor: BLACK,
      },
      construction_category: {
        fillColor: [
          'match',
          ['get', 'construction_category'],
          'Neubau',
          DEEP_BLUE_LIGHT,
          'Erweiterung',
          IMPULSE_ORANGE_MEDIUM,
          NEUTRAL_GREY_60,
        ],
        fillOpacity: 0.7,
        fillOutlineColor: BLACK,
      },
      time_period: {
        fillColor: [
          'match',
          ['get', 'time_period'],
          'bis 2028',
          DEEP_BLUE_LIGHT,
          '2029 bis 2033',
          IMPULSE_ORANGE_MEDIUM,
          NEUTRAL_GREY_60,
        ],
        fillOpacity: 0.7,
        fillOutlineColor: BLACK,
      },
    },
    legend: {
      default: {
        name: 'Infrastruktur',
        unit: '',
        items: [
          {
            color: AQUAMARIN_40,
            name: 'Umspannwerk-Suchräume',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      construction_category: {
        name: 'Umspannwerk-Suchräume Status',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Neubau',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: IMPULSE_ORANGE_MEDIUM,
            name: 'Erweiterung',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Unbekannt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      time_period: {
        name: 'Umspannwerk-Suchräume Zeitraum',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_LIGHT,
            name: 'bis 2028',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: IMPULSE_ORANGE_MEDIUM,
            name: '2029 bis 2033',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Unbekannt',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__GRID_STATIONS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'point',
      id: LAYER_KEY__GRID_STATIONS,
      sourceLayer: LAYER_KEY__GRID_STATIONS,
      layerId: LAYER_KEY__GRID_STATIONS,
      visibility: 'none',
      dependsOnScenario: false,
    },
    style: {
      default: {
        circleColor: AQUAMARIN_70,
        circleStrokeWidth: 1,
        circleStrokeColor: 'black',
        circleRadius: 8,
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: AQUAMARIN_70,
            name: 'Umspannwerke',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
  [LAYER_KEY__HIGH_VOLTAGE_GRID]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__HIGH_VOLTAGE_GRID,
      sourceLayer: LAYER_KEY__HIGH_VOLTAGE_GRID,
      layerId: LAYER_KEY__HIGH_VOLTAGE_GRID,
      visibility: 'none',
      dependsOnScenario: false,
    },
    style: {
      default: {
        lineColor: SUN_YELLOW_40,
        lineWidth: 2,
      },
      ltype: {
        lineColor: [
          'match',
          ['get', 'ltype'],
          'HS-F',
          TAROCCO_40,
          'HS-K',
          DEEP_BLUE_LIGHT,
          NEUTRAL_GREY_60,
        ],
        lineWidth: 2,
      },
    },
    legend: {
      default: {
        name: 'Bestandsinfrastruktur',
        unit: '',
        items: [
          {
            color: SUN_YELLOW_40,
            name: 'Hochspannungsleitung',
            icon: 'mdi-minus',
          },
        ],
      },
      ltype: {
        name: 'Leitungstyp Hochspannung',
        unit: '',
        items: [
          {
            color: TAROCCO_40,
            name: 'Freileitung',
            icon: 'mdi-minus',
          },
          {
            color: DEEP_BLUE_LIGHT,
            name: 'Kabel',
            icon: 'mdi-minus',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Unbekannt',
            icon: 'mdi-minus',
          },
        ],
      },
    },
  },
  [LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS]: {
    layoutState: 'heat_line_density_kwh_am',
    visible: false,
    layerConfig: {
      type: 'line',
      id: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
      sourceLayer: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
      layerId: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
      visibility: 'none',
    },
    sidebar: {
      name: 'Wärmeliniendichte',
      subtitles: ['id', 'municipality_name'],
      chips: [
        {
          title: 'Auswertung',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'buildings_count',
                  unit: '',
                },
                {
                  key: 'heat_demand_kwh_a',
                  unit: 'MWh(th)/a',
                  decimal: 1,
                  multiply: 0.001,
                },
                {
                  key: 'length_m',
                  unit: 'm',
                  decimal: 0,
                },
                {
                  key: 'yearly_heat_line_density_kwh_am',
                  unit: 'kWh(th)/am',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: (year) => ({
        lineColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          AZUR_BLUE_30,
          [
            'interpolate',
            ['linear'],
            ['get', `heat_line_density_kwh_am_${year}`],
            500,
            '#FCEFBD',
            1000,
            '#FEDE82',
            1500,
            '#EDAF00',
            2000,
            '#FE8F11',
            3000,
            '#FE7E58',
            4000,
            '#E2491B',
            5000,
            '#C73B12',
          ],
        ],
        lineWidth: 2,
      }),
    },
    legend: {
      default: {
        name: 'Leitung nach Wärmeliniendichte in kWh(th)/am',
        unit: '',
        items: [
          {
            name: '0 bis 500',
            color: '#FCEFBD',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '501 bis 1000',
            color: '#FEDE82',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '1001 bis 1500',
            color: '#EDAF00',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '1501 bis 2000',
            color: '#FE8F11',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '2001 bis 3000',
            color: '#FE7E58',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '3001 bis 4000',
            color: '#E2491B',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: 'über 5000',
            color: '#C73B12',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
    },
  },
};
