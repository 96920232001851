<template>
  <div
    class="bg-cover flex flex-col justify-between w-full gap-5 min-h-[inherit]"
  >
    <TopHeaderBar />
    <div class="grid grid-flow-row auto-row-auto gap-5 custom-height">
      <div
        v-for="row in cardRows"
        :key="row"
        class="bg-elevated p-5 rounded-[20px] standard-elevation-0-dark flex flex-col gap-5"
      >
        <LandingPageCardRow
          :card-data="cardData.filter((e) => e.row === row)"
          :small-card="row === 2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCase1CardData } from './usecase1-card-data';
import LandingPageCardRow from '@/apps/landing-page/components/LandingPageCardRow.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';

const baseStore = useBaseStore();

baseStore.fetchSimplifiedMuniData();

const router = useRouter();

const cardData = useCase1CardData.map(attachNavigate);
const cardRows = computed(() => {
  const rows = new Set(cardData.map((card) => card.row));
  return Array.from(rows).sort();
});

function attachNavigate(card) {
  return {
    ...card,
    action: () => router.push({ name: card.routeName }),
    children: card.children?.map(attachNavigate),
  };
}
</script>

<style scoped>
.custom-height {
  @media (max-height: 1023px) {
    min-height: calc(100vh - 290px);
  }
  @media (min-height: 1024px) {
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
