<template>
  <ButtonEl
    icon="add"
    icon-fill="text-inverted-color1"
    text="Neu"
    class="w-fit"
    @click="showNewAnalysisDialog = true"
  />
  <VuetifyDialog
    v-if="showNewAnalysisDialog"
    v-model="showNewAnalysisDialog"
    title="Neue Potenzialanalyse anlegen"
    :persistent="true"
    :overflow-visible="true"
    :activator-full-width="false"
  >
    <template #content>
      <div class="flex flex-col gap-5">
        <div class="whitespace-pre-line">
          {{
            `Geben Sie der neuen Potenzialanalyse einen Namen und wählen Sie eine
            Basis aus, um vordefinierte Werte zu übernehmen.`
          }}
        </div>
        <FormValidator v-model="dialogInputIsValid" class="gap-2">
          <InputEl
            v-model="name"
            label="Name Potenzialanalyse"
            placeholder="Bitte eingeben"
            :rules="{
              required: true,
              unique: [potentialsData.map((e) => e.title)],
            }"
          />
          <DropDown
            v-model="basedOnFrameId"
            :items-data="
              [...potentialsData].sort((a, b) => a.title.localeCompare(b.title))
            "
            items-data-key="title"
            value="id"
            :initial-selection="true"
            :rules="{ required: true }"
            label="Die Potenzialanalyse basiert auf:"
          />
        </FormValidator>
      </div>
    </template>
    <template #actions>
      <ButtonEl
        text="Leere Potenzialanalyse anlegen"
        class="mr-auto"
        color="color2"
        variant="secondary"
        @click="createAnalysis(null, name)"
      />
      <ButtonEl
        text="Abbrechen"
        color="color2"
        @click="showNewAnalysisDialog = false"
      />
      <ButtonEl
        text="Anlegen"
        :disabled="!dialogInputIsValid"
        @click="createAnalysis(basedOnFrameId, name)"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { ref } from 'vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import InputEl from '@/components/input/InputEl.vue';
import DropDown from '@/components/DropDown/DropDown.vue';

const emit = defineEmits(['createAnalysis']);
const props = defineProps({ potentialsData: Array });

const dialogInputIsValid = ref(false);
const showNewAnalysisDialog = ref(false);
const basedOnFrameId = ref(null);
const name = ref(null);

function createAnalysis(basedOnFrameId, name) {
  emit('createAnalysis', basedOnFrameId, name);
  showNewAnalysisDialog.value = false;
}
</script>
