<template>
  <div
    class="p-2.5 flex flex-col justify-center items-start gap-2.5 w-full standard-elevation-0-dark rounded-[6px]"
  >
    <div class="flex flex-col gap-1">
      <InfoEntries
        v-for="subtitle in subtitles"
        :key="subtitle.index"
        :subtitle="subtitle"
      />
    </div>
    <div
      v-if="selectedAnalysis"
      class="caption-3 py-2 px-3 rounded-[4px] text-title-neutral bg-subtle"
    >
      <div class="mt-[1px]">Szenario: {{ selectedAnalysis.title }}</div>
    </div>
    <MoreOptions v-if="feature.source === 'heatSourceInventory'">
      <div
        class="flex flex-col whitespace-nowrap rounded-[4px] overflow-hidden border-blue-grey-900 border-[1px] absolute top-0 right-0 bg-white hover:bg-infra-highlight-25 standard-elevation-1 z-10"
      >
        <div
          class="body-3 flex flex-col justify-between items-start cursor-pointer"
        >
          <div class="info-panel-more-options">
            <span @click="emit('open-dialog')">löschen</span>
          </div>
        </div>
      </div>
    </MoreOptions>
  </div>
</template>

<script setup>
import InfoEntries from '@/apps/features/map/info-panel/InfoEntries.vue';
import MoreOptions from '@/components/moreOptions/MoreOptions.vue';
import { storeToRefs } from 'pinia';

const props = defineProps({
  subtitles: Array,
  feature: Object,
  mapStore: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['open-dialog']);

const { selectedAnalysis } = storeToRefs(props.mapStore);
</script>
