<template>
  <div class="flex flex-col gap-5">
    <div class="flex flex-col gap-5 w-1/2">
      <InputEl
        v-model="minAreaHa"
        label="Zusammenhängende Mindestfläche"
        input-type="float"
        placeholder="Bitte eingeben"
        class="w-full"
        :positive-only="true"
        :disabled="readOnly"
        suffix="ha"
      />
      <InputEl
        v-model="maxEstatesOnArea"
        label="Maximale Anzahl Flurstücke auf Fläche"
        input-type="integer"
        placeholder="Bitte eingeben"
        class="w-full"
        :disabled="readOnly"
        :positive-only="true"
        :rules="getValidationRules(formRules.max_count_parcels)"
      />
    </div>

    <div
      class="grid grid-cols-2 gap-5 standard-elevation-0-dark rounded-[8px] p-5"
    >
      <div class="flex flex-col gap-5">
        <CheckboxEl v-model="customSlopeSetting" :disabled="readOnly">
          <span class="subtitle-2 text-title-neutral pt-1 min-h-6"
            >Hangneigung</span
          >
        </CheckboxEl>

        <div class="flex gap-5">
          <InputEl
            v-model="minSlopeDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :positive-only="true"
            :disabled="readOnly"
            class="w-full"
            suffix="°"
            :rules="{
              min: 0,
              max: maxSlopeDegree - 1,
            }"
            border-bottom-static-color="border-color1"
          />
          <InputEl
            v-model="maxSlopeDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :positive-only="true"
            :disabled="readOnly"
            suffix="°"
            class="w-full"
            :rules="{ max: 90 }"
            border-bottom-static-color="border-line-inverted-color1"
          />
        </div>
        <div class="standard-elevation-0-dark rounded-[6px] gap-5 p-5">
          <CircleDegreeMeasure
            v-model="slopeDegreeComputed"
            :orientation-labels="[null, '90°', '0°', null]"
            :start-at="90"
            :clockwise="false"
            :max-valid-angle="90"
          />
        </div>
      </div>

      <div class="flex flex-col gap-5">
        <div class="flex items-center gap-1">
          <CheckboxEl v-model="customOrientationSetting" :disabled="readOnly">
            <span class="subtitle-2 text-title-neutral pt-0.5"
              >Flächenausrichtung</span
            >
          </CheckboxEl>
          <ToolTip class="ml-2">
            <template #text>
              Die Eingabe der Ausrichtung erfolgt in Grad - eine Südausrichtung
              von SO bis SW entspräche dem Bereich von 135° bis 225°. Achtung:
              die Datengrundlage der topografischen Daten liegen in einer
              Auflösung von 100x100m vor. Diese Auflösung kann zu Unschärfen
              hinsichtlich der Hangneigung und -Ausrichtung führen.
            </template>
          </ToolTip>
        </div>
        <div class="flex gap-5">
          <InputEl
            v-model="minOrientationDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :disabled="readOnly"
            :positive-only="true"
            class="w-full"
            suffix="°"
            border-bottom-static-color="border-color1"
          />
          <InputEl
            v-model="maxOrientationDegree"
            input-type="integer"
            placeholder="Bitte eingeben"
            :disabled="readOnly"
            :positive-only="true"
            suffix="°"
            class="w-full"
            border-bottom-static-color="border-line-inverted-color1"
          />
        </div>
        <div class="standard-elevation-0-dark rounded-[6px] gap-5 p-5">
          <CircleDegreeMeasure v-model="orientationDegreeComputed" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CircleDegreeMeasure from '@/apps/usecase-2/potential-analysis/components/CircleDegreeMeasure.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import InputEl from '@/components/input/InputEl.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { getValidationRules } from '@/utils/rest-utils';
import { toRefs, useVModel } from '@vueuse/core';
import { computed, ref, watch, watchEffect } from 'vue';
const props = defineProps({
  formRules: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const {
  min_area_m2: minAreaHa,
  max_count_parcels: maxEstatesOnArea,
  slope_degree_value_greater_equal_than: minSlopeDegree,
  slope_degree_value_smaller_equal_than: maxSlopeDegree,
  orientation_value_greater_equal_than: minOrientationDegree,
  orientation_value_smaller_equal_than: maxOrientationDegree,
} = toRefs(useVModel(props, 'modelValue', emit));

const slopeDegreeComputed = computed(() => {
  return [minSlopeDegree.value ?? 0, maxSlopeDegree.value ?? 90];
});

const orientationDegreeComputed = computed(() => {
  return [minOrientationDegree.value, maxOrientationDegree.value];
});

const customOrientationSetting = ref(true);
const customSlopeSetting = ref(true);

// reset values to max range if no setting is required
watch(customSlopeSetting, (val) => {
  if (!val) {
    minSlopeDegree.value = 0;
    maxSlopeDegree.value = 90;
  }
});

watch(
  customOrientationSetting,
  (val) => {
    if (!val) {
      minOrientationDegree.value = 0;
      maxOrientationDegree.value = 360;
    }
  },
  { immediate: true },
);

// handle the null value for minAreaHa
watchEffect(() => {
  if (minAreaHa.value === null) {
    minAreaHa.value = 0;
  }
});
</script>
