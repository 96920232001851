export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      groups: [
        {
          title: 'Gebäudestruktur',
          id: 'buildingStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeBuildingToggle: true,
                includeAggregationToggle: {
                  partial: false,
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Flurstücke',
          id: 'estateStructure',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Umweltdaten',
          id: 'environmentalData',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Restriktionsflächen',
          id: 'restrictions',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Benachteiligte Flächen',
          id: 'disadvantageArea',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Landnutzung',
          id: 'landUse',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Landwirtschaftliche Parzellen',
          id: 'agriculturePlots',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Planhinweiskarte',
          id: 'planMap',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Straßen und Wege',
          id: 'roads',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Bestandsanalagen',
          id: 'existingPowerPlant',
          items: [
            {
              title: 'Keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      groups: [
        {
          title: 'Mittelspannung ',
          id: 'mediumVoltageGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
        {
          title: 'Hochspannungsnetz ',
          id: 'highVoltageGrid',
          items: [
            {
              title: 'keine Einfärbung',
              itemClass: 'toggle',
              aggregation: {
                includeCustomToggle: {
                  onLayer: '',
                  icon: 'shape_line',
                },
              },
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
  ],
};
