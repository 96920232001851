<template>
  <div class="w-full h-screen relative flex">
    <LayerPanel
      v-if="loaded && !mapStore.searchResults"
      v-show="mapStore.showMainMenu"
      :map-store
    />
    <div class="mapView h-full relative w-full">
      <template v-if="loaded">
        <BaseMap
          :map-store
          :selected-analysis-id="1"
          :layer-config="LayerConfig"
          :panel-objects="panelObjects"
          :fly-to-function="flyToBBox"
        />
        <div class="top-controls absolute w-full">
          <ControlBar :map-store />
          <LegendControls
            :map-store
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
        </div>
        <div class="bottom-controls absolute w-full">
          <MapControls :map-store v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import LayerPanel from '@/apps/features/map/layer-panel/LayerPanel.vue';
import LayerConfig from '@/apps/usecase-2/map/layer-config';
import BaseMap from '@/apps/features/map/BaseMap.vue';
import ControlBar from '@/apps/features/map/control-options/components/ControlBar.vue';
import LegendControls from '@/apps/features/map/control-options/components/LegendControls.vue';
import MapControls from '@/apps/features/map/control-options/components/MapControls.vue';
import { panelObjects } from '@/apps/usecase-2/map/info-panel/Info-panel-objects';
import axios from '@/utils/axiosHelper';
import { fitBBox } from '@/mapbox/main';
import { useMapStore } from './map-store';

const mapStore = useMapStore();

const showLegend = ref(false);
const loaded = ref(false);

initializeMap();

function initializeMap() {
  mapStore.resetConfigs();
  animateControls();
  loaded.value = true;
}

function flyToBBox() {
  return axios({
    method: 'GET',
    url: `/api/usecase-2/initial-map-bbox/${400}/`,
  }).then((resp) => {
    const bbox = resp.data.bbox;
    fitBBox(bbox);
  });
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
