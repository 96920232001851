<template>
  <div v-auto-animate class="flex flex-col gap-3">
    <div class="body-2 text-neutral">{{ subTitle }}</div>
    <h6 v-if="error" class="text-error">
      {{ error }}
    </h6>
    <div v-auto-animate class="flex justify-start gap-5">
      <div
        v-for="(interval, index) in intervals"
        :key="index"
        class="flex flex-col gap-2.5 justify-end"
      >
        <h5 v-if="index === 0" class="text-color1">{{ interval.title }}</h5>
        <div class="flex justify-start gap-1.5">
          <InputEl
            v-model="interval.from"
            :disabled="index === 0"
            label="von"
            input-type="float"
            placeholder="Bitte eingeben"
            suffix="kW"
            class="w-full"
            @update:model-value="!interval.from && (interval.from = 0)"
          />
          <InputEl
            :placeholder="
              index === intervals.length - 1
                ? infinity
                : intervals[index + 1].from.toLocaleString('de-DE')
            "
            :input-type="'string'"
            disabled
            label="bis kleiner"
            suffix="kW"
            class="w-full"
          />
        </div>
        <div class="body-2 text-neutral min-h-[28px] flex items-end">
          {{ index === 0 ? 'Kosten je Trassenkategorie' : '' }}
        </div>
        <div
          v-for="value in interval.values"
          :key="value.index"
          class="flex flex-col gap-2.5"
        >
          <h5 class="text-color1 min-h-[18px]">
            {{ index === 0 ? value.title : '' }}
          </h5>
          <InputEl
            v-model="value.value"
            :label="`${interval.from}-${interval.to} kW`"
            input-type="float"
            placeholder="Bitte eingeben"
            :suffix="value.unit"
            class="w-full"
          />
        </div>
      </div>
      <div class="flex justify-end gap-2.5 pt-[42px]">
        <ButtonEl
          v-if="showRemoveButton"
          icon="remove"
          color="color2"
          variant="secondary"
          class="w-10 h-10"
          @click="removeInterval"
        />
        <ButtonEl
          v-if="showAddButton"
          icon="add"
          color="color2"
          variant="secondary"
          class="w-10 h-10"
          @click="addInterval"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import InputEl from '@/components/input/InputEl.vue';
import { computed, ref, watch } from 'vue';
import { validateIntervals } from '@/apps/usecase-2/settings/invest-costs/utils';

const emit = defineEmits(['add-interval', 'remove-interval', 'update-error']);

defineExpose({ createIntervalInstance });

const props = defineProps({
  title: {
    type: String,
    default: 'title',
  },
  subTitle: {
    type: String,
    default: 'Eigene Leistungsbereiche (Anschlussleistung) definieren',
  },
  categories: {
    type: Array,
    required: true,
  },
  categoryFieldName: {
    type: String,
    required: true,
  },
  groupTitle: {
    type: String,
    default: 'Alle Kategorien',
  },
  createValue: {
    type: Object,
    required: true,
  },
  defaultValue: {
    type: [Number, String],
    required: true,
  },
  maxIntervals: {
    type: Number,
    default: 5,
  },
  intervals: {
    type: Array,
    required: true,
  },
  stepsByLength: {
    type: Object,
    default: () => ({
      1: 1000,
      2: 10000,
      3: 20000,
      4: 40000,
    }),
  },
});

const showAddButton = computed(
  () => props.intervals.length < props.maxIntervals,
);
const showRemoveButton = computed(() => props.intervals.length > 1);
const infinity = ref('∞');
const error = ref();

// Watch for changes in intervals and validate
watch(
  () => props.intervals,
  (newIntervals) => {
    error.value = validateIntervals(newIntervals);
    emit('update-error', error.value);
  },
  { deep: true },
);
/**
 * Creates an interval value instance for a specific category.
 * @param {string} categoryValue - The value associated with the category.
 * @param {string} title - The title for the interval value instance.
 * @param {number|string} [value=props.defaultValue] - The value for the interval instance (defaults to props.defaultValue).
 * @returns {Object} - The created interval value instance.
 */
function createIntervalValueInstance(
  categoryValue,
  title,
  value = props.defaultValue,
) {
  return {
    ...props.createValue,
    [props.categoryFieldName]: categoryValue,
    title,
    value,
  };
}

/**
 * Creates a new interval instance with specified parameters.
 * @param {Object} params - The parameters for creating the interval instance.
 * @param {number} params.from - The starting point of the interval.
 * @param {number|string} params.to - The endpoint of the interval.
 * @param {Array} [params.categories=props.categories] - The categories associated with the interval.
 * @param {number|string} [params.value=props.defaultValue] - The value for the interval (defaults to props.defaultValue).
 * @returns {Object} - The created interval instance.
 */
function createIntervalInstance({
  from,
  to,
  categories = props.categories,
  value = props.defaultValue,
} = {}) {
  return {
    title: props.groupTitle,
    from,
    to,
    values: categories.map((e) =>
      createIntervalValueInstance(
        e.choice,
        e.label,
        e[props.createValue.fieldName] || value,
      ),
    ),
  };
}

/**
 * Adds a new interval to the intervals array and emits the corresponding event.
 * The new interval's starting value is calculated based on existing intervals.
 */
function addInterval() {
  const countIntervals = props.intervals.length;
  const startValue = Math.max(
    props.stepsByLength[countIntervals],
    countIntervals > 1 ? props.intervals[countIntervals - 1].from + 1 : 0,
  );
  emit('add-interval', {
    startValue,
    interval: createIntervalInstance({ from: startValue, to: '∞' }),
  });
}

/**
 * Removes the last interval from the intervals array and emits the corresponding event.
 */
function removeInterval() {
  emit('remove-interval');
}
</script>
