<template>
  <div
    class="checkbox-wrapper select-none"
    :class="{ 'pointer-events-none': disabled }"
    @click="toggle"
  >
    <div :style="checkboxStyle" class="checkbox" :class="checkboxCustomClasses">
      <svg
        v-show="isChecked"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        :style="tickStyle"
      >
        <!-- Using both fill and stroke for a thicker appearance -->
        <path
          :fill="tickColor"
          :stroke="tickColor"
          stroke-width="1"
          d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"
        />
      </svg>
    </div>
    <slot />
    <label v-if="label" class="body-2 !leading-[21px] !h-5 ml-1.5">{{
      label
    }}</label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: [Array, Boolean],
    default: () => false,
  },
  value: {
    type: [String, Number, Object, Boolean],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  backgroundColor: {
    type: String,
    default: 'bg-button-primary-default-color1',
  },
  tickColor: {
    type: String,
    default: '#fff',
  },
  size: {
    type: String,
    default: '16px',
  },
  borderColor: {
    type: String,
    default: 'border-title-neutral',
  },
  borderRadius: {
    type: String,
    default: '2px',
  },
  borderWidth: {
    type: String,
    default: '2px',
  },
  borderOnChecked: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const isChecked = computed(() => {
  if (Array.isArray(props.modelValue)) {
    if (typeof props.value === 'object') {
      return props.modelValue.some(
        (item) => JSON.stringify(item) === JSON.stringify(props.value),
      );
    }
    return props.modelValue.includes(props.value);
  }
  return props.modelValue;
});

const toggle = (event) => {
  if (props.disabled) {
    event.preventDefault();
    return;
  }
  let updatedValue;
  if (Array.isArray(props.modelValue)) {
    if (isChecked.value) {
      if (typeof props.value === 'object') {
        updatedValue = props.modelValue.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(props.value),
        );
      } else {
        updatedValue = props.modelValue.filter((item) => item !== props.value);
      }
    } else {
      updatedValue = [...props.modelValue, props.value];
    }
  } else {
    updatedValue = !props.modelValue;
  }
  emit('update:modelValue', updatedValue);
};

const checkboxCustomClasses = computed(() => {
  return {
    [props.borderColor]: props.borderColor && !props.disabled,
    [props.backgroundColor]:
      isChecked.value && props.backgroundColor && !props.disabled,
    'bg-disabled-neutral': props.disabled && isChecked.value,
    '!border-disabled-neutral': props.disabled && !isChecked.value,
  };
});

const checkboxStyle = computed(() => {
  let borderStyle = `${props.borderWidth} solid`;

  if (isChecked.value && !props.borderOnChecked) {
    borderStyle = 'none';
  }

  return {
    minWidth: props.size,
    minHeight: props.size,
    borderRadius: props.borderRadius,
    border: borderStyle,
    cursor: props.disabled ? 'default' : 'pointer',
  };
});

const tickStyle = computed(() => ({
  width: tickSize.value,
  height: tickSize.value,
  position: 'absolute',
  top: '48%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const tickSize = computed(() => {
  const size = parseInt(props.size, 10);
  return size / 1.1 + 'px'; // Adjust tick size here based on your design
});
</script>

<style scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 6px;
  /* You can adjust the gap between the checkbox and slot content */
  cursor: pointer;
}

.checkbox {
  display: inline-block;
  position: relative;
  user-select: none; /* Prevent text selection on double click */
}
</style>
