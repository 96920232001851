<template>
  <div class="flex flex-col gap-7">
    <div class="flex flex-col gap-5">
      <div class="flex gap-1">
        <h3 class="text-color1">Netzausbau Umspannwerke Mittelspannung</h3>
        <ToolTip
          :tooltip-text="'Dargestellt ist eine reine\nEinspeisebetrachtung. Die Last ist\nin der Darstellung vernachlässigt.'"
        />
      </div>
      <div class="projects-grid-wrapper">
        <div class="projects-grid-item h-[340px] shadow-none">
          <WaterfallChart
            class="p-5 rounded-[4px]"
            :data="windPvFreePrediction"
            :labels="labelsPower"
            :title="'Leistungszuwachs\n PV-Freifläche und Wind'"
            grid-top="70px"
            title-top="0px"
            unit="MW(el)"
            y-axis-name="MW(el)"
            grid-left="7%"
            :show-y-axis-line="true"
          />
        </div>

        <div class="projects-grid-item h-[340px] p-5">
          <div
            class="flex flex-col gap-5 items-center justify-start h-full w-full"
          >
            <h4 class="text-title-neutral text-center">
              <span class="inline-flex items-center">
                Anzahl Umspannwerke
                <ToolTip
                  class="ml-1"
                  :tooltip-text="
                    'Bestimmung der Anzahl Umspannwerke basierend auf\n' +
                    'Leistungszuwachs nach Berücksichtigung des DC/AC-Ratios.'
                  "
                />
              </span>
              <br />(Neubau/Erweiterung)
            </h4>
            <ArrowNumberCard
              class="min-w-[190px]"
              :model-value="Math.ceil(requiredSubstations)"
              :sub-text="`gemäß\nLeistungszuwachs aus\nPV-Freifläche und Wind\nbis ${resultStore.activeProject.target_year}`"
            />
          </div>
        </div>
        <div class="projects-grid-item shadow-none h-[340px]">
          <div class="grid grid-flow-row gap-2.5 h-full">
            <div
              v-for="item in subStationsData"
              :key="item"
              class="flex items-center justify-between gap-[15px] w-full px-7 py-2.5 standard-elevation-0-dark rounded-[4px]"
            >
              <div class="flex items-center gap-[15px]">
                <IconWrapper
                  fill="text-title-neutral"
                  :icon="item.icon"
                  :size="32"
                />
                <h6 class="text-neutral">{{ item.text }}</h6>
              </div>
              <div
                class="text-title-neutral flex items-center whitespace-nowrap"
              >
                {{ item.value + (item.unit ? item.unit : '') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-5">
      <div class="flex gap-1">
        <h3 class="text-color1">Netzausbau Trafo-Stationen Niederspannung</h3>
        <ToolTip
          :tooltip-text="'Die Anzahl der Trafos bemisst sich\naus dem höheren Zuwachs\n(Einspeiseleistung bzw. Last)'"
        />
      </div>
      <div class="projects-grid-wrapper">
        <div class="projects-grid-item h-[340px] shadow-none">
          <WaterfallChart
            class="p-5 rounded-[4px]"
            :data="powerLoadPrediction"
            :labels="labelsLoad"
            :title="'Stromlastzuwachs\n(nach Gleichzeitigkeit)'"
            grid-top="70px"
            title-top="0px"
            unit="MW(el)"
            :show-y-axis-line="true"
            y-axis-name="MW(el)"
            grid-left="7%"
          />
        </div>
        <div class="projects-grid-item h-[340px] shadow-none">
          <WaterfallChart
            class="p-5 rounded-[4px]"
            :data="pvRoofPrediction"
            :labels="labelsPower"
            :title="'Leistungszuwachs\nPV-Aufdach'"
            grid-top="70px"
            title-top="0px"
            unit="MW(el)"
            :show-y-axis-line="true"
            y-axis-name="MW(el)"
            grid-left="9%"
          />
        </div>
        <div class="projects-grid-item h-[340px] p-5">
          <div
            class="flex flex-col gap-5 items-center justify-start h-full w-full"
          >
            <h4 class="text-title-neutral text-center">
              Anzahl Trafostationen <br />
              (Neubau/Erweiterung)
            </h4>
            <ArrowNumberCard
              class="min-w-[190px]"
              :model-value="Math.ceil(requiredTrafoStations)"
              :sub-text="`gemäß\n${trafoDriver}\nbis ${resultStore.activeProject.target_year}`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <SourcesItem item="grid-planning" />
</template>

<script setup>
import { computed, watch } from 'vue';
import { POWER_DEFAULT } from '@/configs/color-constants';
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';
import ArrowNumberCard from '@/apps/usecase-1/projects/project-result-detail/components/ArrowNumberCard.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import { useProjectResult } from './project-result-store';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import IconTrafo from '@/assets/icons/custom/energy-grid/IconTrafo.vue';

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const resultStore = useProjectResult();

await resultStore.fetchDemandData(props.projectId, props.scenarioId);
await resultStore.fetchInfrastructureData(props.projectId, props.scenarioId);
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);

await resultStore.applyDefaultDemandScaling(props.scenarioId);
await resultStore.applyDefaultGenerationScaling(props.scenarioId);

// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

const infrastructureData = computed(
  () => resultStore.infrastructureData[props.scenarioId],
);

const requiredSubstations = computed(
  () =>
    ((resultStore.appliedTotalIncreasePvFreeMw +
      resultStore.appliedTotalIncreaseWindMw) /
      resultStore.assumptionsGeneral.specific_power_substation_mw) *
    resultStore.assumptionsGeneral.dc_ac_ratio,
);

const trafoDriver = computed(() => {
  const {
    appliedScaledDemandTotalMw,
    demandStatusQuoMw,
    appliedTotalIncreasePvRoofMw,
  } = resultStore;
  const growthLoad = appliedScaledDemandTotalMw - demandStatusQuoMw;
  if (growthLoad > appliedTotalIncreasePvRoofMw) return 'Stromlastzuwachs';
  return 'Leistungszuwachs\nPV-Aufdach';
});

const requiredTrafoStations = computed(() => {
  const {
    appliedScaledDemandTotalMw,
    demandStatusQuoMw,
    appliedTotalIncreasePvRoofMw,
    assumptionsGeneral,
  } = resultStore;
  const growthLoad = appliedScaledDemandTotalMw - demandStatusQuoMw;
  const growth = Math.max(growthLoad, appliedTotalIncreasePvRoofMw);
  return growth / assumptionsGeneral.specific_power_trafo_mw;
});
const labelsLoad = [
  'Last heute',
  'Lastzuwachs',
  `Last ${resultStore.activeProject.target_year}`,
];
const labelsPower = [
  'Leistung heute',
  'Leistungszuwachs',
  `Leistung ${resultStore.activeProject.target_year}`,
];
const windPvFreePrediction = computed(() => {
  const {
    generationPvFreeMw,
    generationWindMw,
    appliedTotalIncreasePvFreeMw,
    appliedTotalIncreaseWindMw,
  } = resultStore;
  const generation = generationPvFreeMw + generationWindMw;
  const growth = appliedTotalIncreasePvFreeMw + appliedTotalIncreaseWindMw;
  const total = generation + growth;

  const { POWER_TODAY, POWER_INCREASE, POWER_TARGET } = POWER_DEFAULT;

  return {
    visibleStacks: [
      { value: generation, itemStyle: { color: POWER_TODAY } },
      { value: growth, itemStyle: { color: POWER_INCREASE } },
      { value: total, itemStyle: { color: POWER_TARGET } },
    ],
    hiddenStacks: [0, generation, 0],
  };
});

const powerLoadPrediction = computed(() => {
  const { demandStatusQuoMw, appliedScaledDemandTotalMw } = resultStore;
  const { POWER_TODAY, POWER_INCREASE, POWER_TARGET } = POWER_DEFAULT;

  return {
    visibleStacks: [
      { value: demandStatusQuoMw, itemStyle: { color: POWER_TODAY } },
      {
        value: appliedScaledDemandTotalMw - demandStatusQuoMw,
        itemStyle: { color: POWER_INCREASE },
      },
      { value: appliedScaledDemandTotalMw, itemStyle: { color: POWER_TARGET } },
    ],
    hiddenStacks: [0, demandStatusQuoMw, 0],
  };
});

const pvRoofPrediction = computed(() => {
  const { generationPvRoofMw, appliedTotalIncreasePvRoofMw } = resultStore;
  const { POWER_TODAY, POWER_INCREASE, POWER_TARGET } = POWER_DEFAULT;

  return {
    visibleStacks: [
      { value: generationPvRoofMw, itemStyle: { color: POWER_TODAY } },
      {
        value: appliedTotalIncreasePvRoofMw,
        itemStyle: { color: POWER_INCREASE },
      },
      {
        value: generationPvRoofMw + appliedTotalIncreasePvRoofMw,
        itemStyle: { color: POWER_TARGET },
      },
    ],
    hiddenStacks: [0, generationPvRoofMw, 0],
  };
});

const subStationsData = computed(() => [
  {
    icon: 'settings_overscan',
    text: 'Flächenbedarf Umspannwerke (Neubau/Erweiterung)',
    value: (
      Math.ceil(requiredSubstations.value) *
      resultStore.assumptionsGeneral.area_per_substation_ha
    ).toLocaleString('de-DE', {
      maximumFractionDigits: 0,
    }),
    unit: ' ha',
  },
  {
    icon: IconTrafo,
    text: 'Anzahl bestehender Umspannwerke im Projektgebiet',
    value: infrastructureData.value.count_grid_stations,
    unit: null,
  },
  {
    icon: 'image_search',
    text: 'Suchraum neuer Umspannwerke im Projektgebiet',
    value: (
      infrastructureData.value.search_area_overlap_m2 / 10000
    ).toLocaleString('de-DE', { maximumFractionDigits: 0 }),
    unit: ' ha',
  },
]);

watch(
  () => props.scenarioId,
  async () => {
    await resultStore.applyDefaultGenerationScaling(props.scenarioId);
  },
);
</script>

<style scoped></style>
