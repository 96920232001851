<template>
  <div
    :class="{ 'opacity-50 pointer-events-none': disabled }"
    @click="executeClick"
  >
    <slot />
  </div>
  <VuetifyDialog
    v-if="markedForDelete === id"
    v-model="dialog"
    :title="`${nameDeletedItem} wirklich löschen?`"
    activator-class="absolute"
  >
    <template #content>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </template>
    <template #actions>
      <div class="flex justify-center w-full gap-5">
        <ButtonEl :primary="false" text="Abbrechen" @click="dialog = false" />
        <ButtonEl
          text="Löschen"
          color="error"
          @click="deleteInstance(markedForDelete)"
        />
      </div>
    </template>
  </VuetifyDialog>
</template>
<script setup>
import { ref } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { restDeleteById } from '@/utils/rest-utils';
import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();

const props = defineProps({
  id: {
    type: [String, Number],
  },
  dto: {
    type: Function,
  },
  url: {
    type: String,
  },
  nameDeletedItem: {
    type: String,
    default: 'Auswahl',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['instance-removed']);

const markedForDelete = ref({});
const dialog = ref(false);

function deleteInstance(id) {
  dialog.value = false;
  restDeleteById(props.dto || { BASE_URL: props.url }, id)
    .then(() => {
      emit('instance-removed');
      showToast({
        color: 'success',
        message: 'Eintrag gelöscht',
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

function executeClick() {
  if (props.disabled) return;
  dialog.value = true;
  markedForDelete.value = props.id;
}
</script>
